import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, 
  Heading, 
  Switch, 
  Text, 
  VStack, 
  HStack, 
  Grid, 
  GridItem,
  Tooltip,
  useToast,
  Input,
  FormLabel,
  FormControl,
  FormHelperText,
  Button,
  InputGroup,
  InputLeftAddon,
  useDisclosure,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Select,
  useClipboard,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useUser } from '../../../contexts/UserContext';
import EmailTemplateEditor from './EmailTemplateEditor';
import EmailComparison from './EmailComparison'; 
import { useNavigate } from 'react-router-dom';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

const Settings = () => {
  const { user, setUser } = useUser();
  const [isBetaFeatures, setIsBetaFeatures] = useState(false);
  const [useEmailTemplate, setUseEmailTemplate] = useState(false);
  const [useEmailVoice, setUseEmailVoice] = useState(false);
  const [showEmailComparison, setShowEmailComparison] = useState(false);
  const [hasMicrosoftIntegration, setHasMicrosoftIntegration] = useState(false);
  const [hasGoogleIntegration, setHasGoogleIntegration] = useState(false);
  const [emailTemplate, setEmailTemplate] = useState('');
  const [signatureImage, setSignatureImage] = useState(null);
  const fileInputRef = useRef(null);
  const toast = useToast();
  const serverUrl = process.env.REACT_APP_API_URL;
  const standardEmail = `Dear [Client],

I hope this email finds you well. I wanted to follow up on our previous discussion regarding your investment portfolio.

Best regards,
[Name]`;
  const voicedEmail = `Hey [Client]!

Just touching base about what we chatted about last time - you know, that investment portfolio stuff we discussed. I think we've got some really exciting options to explore.

Catch you soon,
[Name]`;
  const defaultTemplate = `Dear {{clientName}},
Thank you for meeting with me on {{todaysDate}}. 

{{body}}.

Please let me know if you have any questions or concerns.

Best regards,
{{advisorName}}
{{signatureImage}}`;

  const quillRef = useRef(null);
  const [manuallyAssignTasks, setManuallyAssignTasks] = useState(false);
  const [enableAssignToDataDasher, setEnableAssignToDataDasher] = useState(false);
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);
  const [twoFactorPhone, setTwoFactorPhone] = useState('');
  const [isEditingPhone, setIsEditingPhone] = useState(false);
  const [twoFactorPhoneVerified, setTwoFactorPhoneVerified] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const navigate = useNavigate();
  const [isUserLoading, setIsUserLoading] = useState(true);
  const [aiInsightsEnabled, setAiInsightsEnabled] = useState(false);
  const [organizationUsers, setOrganizationUsers] = useState([]);
  const [userFilter, setUserFilter] = useState('all');
  const [inviteLink, setInviteLink] = useState('');
  const { onCopy, hasCopied } = useClipboard(inviteLink);
  const [emailBeforeMeeting, setEmailBeforeMeeting] = useState(false);
  const [emailForAllMeetings, setEmailForAllMeetings] = useState(false);
  const [localTime, setLocalTime] = useState('09:00');
  const [utcTime, setUtcTime] = useState('09:00');
  const [userToRemove, setUserToRemove] = useState(null);
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);
  const [isPauseModalOpen, setIsPauseModalOpen] = useState(false);
  const [pauseDuration, setPauseDuration] = useState(1);
  const [isLoadingPortal, setIsLoadingPortal] = useState(false);
  const [isLoadingPause, setIsLoadingPause] = useState(false);

  const formatPhoneForDisplay = (phoneString) => {
    if (!phoneString) return '';
    // Remove all non-numeric characters
    const numbers = phoneString.replace(/\D/g, '');
    // Remove +1 if it exists at the start
    const cleaned = numbers.startsWith('1') ? numbers.substring(1) : numbers;
    
    if (cleaned.length >= 6) {
      return `(${cleaned.substring(0,3)}) ${cleaned.substring(3,6)}-${cleaned.substring(6)}`;
    } else if (cleaned.length >= 3) {
      return `(${cleaned.substring(0,3)}) ${cleaned.substring(3)}`;
    } else if (cleaned.length > 0) {
      return `(${cleaned}`;
    }
    return '';
  };

  const formatPhoneForStorage = (phoneString) => {
    // Remove all non-numeric characters
    const numbers = phoneString.replace(/\D/g, '');
    // Ensure it starts with +1
    return `+1${numbers.substring(numbers.startsWith('1') ? 1 : 0)}`;
  };

  useEffect(() => {
    const checkIntegrations = async () => {
      try {
        let token = Cookies.get('jwtToken');
        const response = await axios.get(`${serverUrl}/api/user-connections`, {
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: true
        });
        console.log('Connections:', response.data);
        setHasMicrosoftIntegration(response.data.some(conn => conn.name === 'Microsoft'));
        setHasGoogleIntegration(response.data.some(conn => conn.name === 'Google'));

        const settingsResponse = await axios.get(`${serverUrl}/api/user-settings`, {
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: true
        });
        const settings = settingsResponse.data;
        console.log('settings', settings);
        setUseEmailVoice(settings?.settings?.useEmailVoice || false);
        setUseEmailTemplate(settings?.settings?.useEmailTemplate || false);
        setEmailTemplate(settings?.settings?.emailTemplate || defaultTemplate);
        setSignatureImage(settings?.settings?.signatureImage || null);
        setTwoFactorEnabled(settings?.twoFactorEnabled || false);
        setTwoFactorPhone(settings?.twoFactorPhone ? formatPhoneForDisplay(settings.twoFactorPhone) : '');
        setTwoFactorPhoneVerified(settings?.twoFactorPhoneVerified || false);
      } catch (error) {
        console.error('Error fetching user connections:', error);
      }
    };

    checkIntegrations();
  }, [serverUrl]);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const token = Cookies.get('jwtToken');
        const response = await axios.get(`${serverUrl}/api/user-settings`, {
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: true
        });
        
        const settings = response.data;
        // Update all settings from the response
        setAiInsightsEnabled(settings?.aiInsightsEnabled || false);
        setManuallyAssignTasks(settings.settings?.manuallyAssignTasks || false);
        setEnableAssignToDataDasher(settings.settings?.enableAssignToDataDasher || false);
        setEmailBeforeMeeting(settings.emailBeforeMeeting || false);
        setEmailForAllMeetings(settings.emailForAllMeetings || false);
        
        // Convert UTC time from server to local time for display
        if (settings.dailyEmailTime) {
          const [utcHours, utcMinutes] = settings.dailyEmailTime.split(':').map(Number);
          const date = new Date();
          date.setUTCHours(utcHours, utcMinutes, 0, 0);
          
          const localHours = date.getHours().toString().padStart(2, '0');
          const localMinutes = date.getMinutes().toString().padStart(2, '0');
          const localTimeString = `${localHours}:${localMinutes}`;
          
          setLocalTime(localTimeString);
          setUtcTime(settings.dailyEmailTime);
        } else {
          setLocalTime('09:00');
          setUtcTime('09:00');
        }
        
        setIsBetaFeatures(settings.betaFeaturesEnabled || false);
        
        // Update user context with beta features
        setUser(prevUser => ({
          ...prevUser,
          betaFeaturesEnabled: settings.betaFeaturesEnabled || false
        }));
        
        setIsUserLoading(false);
      } catch (error) {
        console.error('Error fetching settings:', error);
        setIsUserLoading(false);
      }
    };
    
    fetchSettings();
  }, [serverUrl, setUser]);

  

  useEffect(() => {
    const fetchOrganizationUsers = async () => {
      if(!user) return;
      if(user.role !== 'admin') return;
      try {
        const response = await axios.get(`${serverUrl}/api/organization/${user.organization.id}/users`, {
          headers: { Authorization: `Bearer ${Cookies.get('jwtToken')}` }
        });
        setOrganizationUsers(response.data.users);
      } catch (error) {
        console.error('Error fetching organization users:', error);
        toast({
          title: 'Error fetching users',
          description: error.response?.data?.error || 'Failed to fetch organization users',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    };

    fetchOrganizationUsers();
  }, [user, serverUrl]);

  const handleEmailVoiceToggle = async () => {
    if (!hasMicrosoftIntegration) return;

    try {
      const response = await axios.post(`${serverUrl}/api/user-settings`, {
        settings: {
          settings: {
            useEmailVoice: !useEmailVoice
          }
        }
      });

      if (response.status !== 200) throw new Error('Failed to update settings');

      setUseEmailVoice(!useEmailVoice);
      setShowEmailComparison(!useEmailVoice);

      // Update the user context
      setUser((prevUser) => ({
        ...prevUser,
        settings: {
          ...prevUser.settings,
          useEmailVoice: !useEmailVoice,
        },
      }));

      toast({
        title: 'Settings updated',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      // No need to reload the page
    } catch (error) {
      toast({
        title: 'Error updating settings',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEmailTemplateToggle = async () => {
    if (!hasMicrosoftIntegration) return;

    try {
      const response = await axios.post(`${serverUrl}/api/user-settings`, {
        settings: {
          settings: {
            useEmailTemplate: !useEmailTemplate,
            emailTemplate: emailTemplate || defaultTemplate
          }
        }
      });

      if (response.status !== 200) throw new Error('Failed to update settings');

      setUseEmailTemplate(!useEmailTemplate);
      if (!emailTemplate) setEmailTemplate(defaultTemplate);

      toast({
        title: 'Settings updated',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error updating settings',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleTemplateChange = async (content) => {
    setEmailTemplate(content);
    try {
      await axios.post(`${serverUrl}/api/user-settings`, {
        settings: {
          settings: {
            emailTemplate: content
          }
        }
      });
    } catch (error) {
      console.error('Error saving template:', error);
    }
  };

  const handleSignatureUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = async () => {
      if (img.width < 2550) {
        toast({
          title: 'Image too small',
          description: 'Signature image must be at least 2550 pixels wide',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      try {
        const formData = new FormData();
        formData.append('signature', file);
        const token = Cookies.get('jwtToken');
        const response = await axios.post(`${serverUrl}/api/upload-signature`, formData, {
          headers: { 
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          },
          withCredentials: true
        });

        setSignatureImage(response.data.url);
        toast({
          title: 'Signature uploaded successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: 'Error uploading signature',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    };
  };

  const handleManualTaskAssignmentChange = async () => {
    try {
      await axios.post(`${serverUrl}/api/organization-settings`, {
        settings: {
          manuallyAssignTasks: !manuallyAssignTasks
        }
      });

      setManuallyAssignTasks(!manuallyAssignTasks);

      // Update the user context with the new setting
      setUser((prevUser) => ({
        ...prevUser,
        organization: {
          ...prevUser.organization,
          settings: {
            ...prevUser.organization.settings,
            manuallyAssignTasks: !manuallyAssignTasks,
          },
        },
      }));

      toast({
        title: "Settings updated",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      // window.location.reload();
    } catch (error) {
      console.error('Error updating settings:', error);
      toast({
        title: "Error updating settings",
        status: "error",
        duration: "3000",
        isClosable: true,
      });
    }
  };

  const handleDataDasherAssignmentChange = async () => {
    try {
      await axios.post(`${serverUrl}/api/organization-settings`, {
        settings: {
          enableAssignToDataDasher: !enableAssignToDataDasher
        }
      }, {
        headers: { Authorization: `Bearer ${Cookies.get('jwtToken')}` }
      });
      
      setEnableAssignToDataDasher(!enableAssignToDataDasher);

      // Update the user context with the new setting
      setUser((prevUser) => ({
        ...prevUser,
        organization: {
          ...prevUser.organization,
          settings: {
            ...prevUser.organization.settings,
            enableAssignToDataDasher: !enableAssignToDataDasher,
          },
        },
      }));

      toast({
        title: "Settings updated",
        status: "success",
        duration: "3000",
        isClosable: true,
      });
      // Removed window.location.reload();
    } catch (error) {
      console.error('Error updating settings:', error);
      toast({
        title: "Error updating settings",
        status: "error",
        duration: "3000",
        isClosable: true,
      });
    }
  };

  const handleTwoFactorToggle = async () => {
    setTwoFactorEnabled(!twoFactorEnabled);
    setIsEditingPhone(!twoFactorEnabled);

    try {
      const response = await axios.post(`${serverUrl}/api/user-settings`, {
        settings: {
          twoFactorEnabled: !twoFactorEnabled
        }
      });

      if (response.status !== 200) throw new Error('Failed to update settings');

      setUser((prevUser) => ({
        ...prevUser,
        twoFactorEnabled: !twoFactorEnabled,
        twoFactorPhone: twoFactorPhone
      }));

      toast({
        title: `Two-factor authentication ${!twoFactorEnabled ? 'enabled' : 'disabled'}`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error updating two-factor authentication',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handlePhoneChange = (e) => {
    // Remove all non-numeric characters
    let phoneNumber = e.target.value.replace(/\D/g, '');
    
    // Limit to 10 digits
    phoneNumber = phoneNumber.substring(0, 10);
    
    // Format as (XXX) XXX-XXXX
    if (phoneNumber.length >= 6) {
      phoneNumber = `(${phoneNumber.substring(0,3)}) ${phoneNumber.substring(3,6)}-${phoneNumber.substring(6)}`;
    } else if (phoneNumber.length >= 3) {
      phoneNumber = `(${phoneNumber.substring(0,3)}) ${phoneNumber.substring(3)}`;
    } else if (phoneNumber.length > 0) {
      phoneNumber = `(${phoneNumber}`;
    }
    
    setTwoFactorPhone(phoneNumber);
  };

  const handlePhoneSubmit = async () => {
    try {
      const formattedPhone = formatPhoneForStorage(twoFactorPhone);
      
      const response = await axios.post(`${serverUrl}/api/user-settings`, {
        settings: {
          twoFactorPhone: formattedPhone,
          twoFactorEnabled: true,
          twoFactorPhoneVerified: false
        }
      });

      if (response.status !== 200) throw new Error('Failed to update phone number');

      setIsEditingPhone(false);
      setTwoFactorPhoneVerified(false);
      setUser((prevUser) => ({
        ...prevUser,
        twoFactorPhone: formattedPhone,
        twoFactorEnabled: true,
      }));

      toast({
        title: 'Phone number updated',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error updating phone number',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleVerifyPhone = async () => {
    try {
      const formattedPhone = formatPhoneForStorage(twoFactorPhone);
      
      const response = await axios.post(`${serverUrl}/api/verify-two-factor`, {
        phone: formattedPhone
      }, {
        headers: { Authorization: `Bearer ${Cookies.get('jwtToken')}` }
      });

      if (response.status !== 200) throw new Error('Failed to send verification code');

      toast({
        title: "Verification code sent",
        description: response.data.message || "Please check your phone for the verification code",
        status: "info", 
        duration: 5000,
        isClosable: true,
      });

      // Prompt for verification code
      const code = prompt("Please enter the verification code sent to your phone:");
      
      if (code) {
        const verifyResponse = await axios.post(`${serverUrl}/api/verify-two-factor-code`, {
          code
        }, {
          headers: { Authorization: `Bearer ${Cookies.get('jwtToken')}` }
        });

        if (verifyResponse.status === 200) {
          setTwoFactorPhoneVerified(true);
          setUser((prevUser) => ({
            ...prevUser,
            settings: {
              ...prevUser.settings,
              twoFactorPhoneVerified: true
            }
          }));

          toast({
            title: "Phone number verified", 
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }
      }
    } catch (error) {
      console.log(error.response.data);
      toast({
        title: "Error verifying phone number",
        description: error.response?.data?.error || error.response?.data?.message || error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteAccount = async () => {
    try {
      const userId = user.id;
      await axios.delete(`${serverUrl}/api/users/${userId}`, {
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      });
      
      // Clear cookies and redirect to login
      Cookies.remove('jwtToken');
      Cookies.remove('deviceToken');
      navigate('/login');
      
      toast({
        title: "Account Deleted",
        description: "Your account has been successfully deleted.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting account:', error);
      toast({
        title: "Error",
        description: "Failed to delete account. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleBetaToggle = async () => {
    if (!user) {
      toast({
        title: "Error",
        description: "User information not available",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // Immediately update UI
    const newBetaState = !isBetaFeatures;
    setIsBetaFeatures(newBetaState);

    try {
      // Update both endpoints to ensure consistency
      const [settingsResponse, userResponse] = await Promise.all([
        axios.post(`${serverUrl}/api/user-settings`, 
          { settings: { betaFeaturesEnabled: newBetaState } },
          { headers: { Authorization: `Bearer ${Cookies.get('jwtToken')}` } }
        ),
        axios.patch(
          `${serverUrl}/api/users/${user.id}/settings`,
          { betaFeaturesEnabled: newBetaState },
          { headers: { Authorization: `Bearer ${Cookies.get('jwtToken')}` } }
        )
      ]);

      if (settingsResponse.status === 200 && userResponse.status === 200) {
        // Update user context
        setUser(prevUser => ({
          ...prevUser,
          betaFeaturesEnabled: newBetaState
        }));

        toast({
          title: "Settings Updated",
          description: `Beta features ${newBetaState ? 'enabled' : 'disabled'} successfully.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      // Revert UI state on error
      setIsBetaFeatures(!newBetaState);
      setUser(prevUser => ({
        ...prevUser,
        betaFeaturesEnabled: !newBetaState
      }));
      
      console.error('Error updating beta settings:', error);
      toast({
        title: "Error",
        description: "Failed to update beta settings",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAiInsightsToggle = async () => {
    if (!isBetaFeatures) return;

    try {
      const response = await axios.post(`${serverUrl}/api/user-settings`, {
        settings: {
          aiInsightsEnabled: !aiInsightsEnabled
        }
      });

      console.log("RESPONSE:", response);

      if (response.status !== 200) throw new Error('Failed to update settings');

      setAiInsightsEnabled(!aiInsightsEnabled);
      
      // Update the user context with the new setting
      setUser((prevUser) => ({
        ...prevUser,
        settings: {
          ...prevUser.settings,
          aiInsightsEnabled: !aiInsightsEnabled,
        },
      }));

      toast({
        title: `AI Insights ${!aiInsightsEnabled ? 'enabled' : 'disabled'}`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error updating AI Insights setting',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const getUserStatus = (user) => {
    if (user.inviteStatus === 'pending') return 'pending';
    if (user.inviteStatus === 'active') return 'active';
    if (user.inviteStatus === 'declined') return 'declined';
    return 'unknown';
  };

  const getFilteredUsers = () => {
    return organizationUsers.filter(user => {
      if (userFilter === 'all') return true;
      const status = getUserStatus(user);
      return status === userFilter;
    });
  };

  const handleInviteColleagues = () => {
    if (!user?.organization?.id) {
      toast({
        title: 'Error',
        description: 'Organization ID not found',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const link = `${window.location.origin}/signup?orgId=${user.organization.id}`;
    setInviteLink(link);
    onCopy();
    
    toast({
      title: hasCopied ? 'Invite link copied!' : 'Generated new invite link',
      description: 'Share this link with your colleagues to invite them to your organization',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  const isAdmin = () => {
    console.log("USER:", user);
    return user?.role === 'admin';
  };

  const handleUserStatus = async (userId, status) => {
    try {
      const response = await axios.post(
        `${serverUrl}/api/organization/${user.organization.id}/users/${userId}/approve`,
        { status },
        {
          headers: { Authorization: `Bearer ${Cookies.get('jwtToken')}` }
        }
      );

      if (response.status === 200) {
        // Update the local state to reflect the change
        setOrganizationUsers(prevUsers => 
          prevUsers.map(u => 
            u.id === userId 
              ? { ...u, inviteStatus: status }
              : u
          )
        );

        toast({
          title: `User ${status === 'active' ? 'approved' : 'rejected'}`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error(`Error updating user status:`, error);
      toast({
        title: `Error updating user status`,
        description: error.response?.data?.error || `Failed to update user status`,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const updateSettings = async (newSettings) => {
    try {
      await axios.post(`${serverUrl}/api/user-settings`, { settings: newSettings });
    } catch (error) {
      console.error('Error updating settings:', error);
    }
  };

  const handleToggleChange = (settingName, value) => {
    const newSettings = { [settingName]: value };
    updateSettings(newSettings);
  };

  const handleInputBlur = (settingName, value) => {
    const newSettings = { [settingName]: value };
    updateSettings(newSettings);
  };

  const handleRemoveUser = async (userId) => {
    try {
      const response = await axios.post(
        `${serverUrl}/api/organization/${user.organization.id}/users/${userId}/remove`,
        {},
        {
          headers: { Authorization: `Bearer ${Cookies.get('jwtToken')}` }
        }
      );

      if (response.status === 200) {
        // Update the local state to remove the user
        setOrganizationUsers(prevUsers => 
          prevUsers.filter(u => u.id !== userId)
        );

        toast({
          title: 'User removed successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error removing user:', error);
      toast({
        title: 'Error removing user',
        description: error.response?.data?.error || 'Failed to remove user',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsRemoveDialogOpen(false);
      setUserToRemove(null);
    }
  };

  const handleManageSubscription = async () => {
    try {
      setIsLoadingPortal(true);
      const response = await axios.post(
        `${serverUrl}/stripe/create-portal-session`,
        {},
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
          },
        }
      );
      
      if (response.data && response.data.url) {
        window.location.href = response.data.url;
      }
    } catch (error) {
      console.error('Error creating portal session:', error);
      toast({
        title: 'Error',
        description: 'Failed to create billing portal session. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoadingPortal(false);
    }
  };

  const handlePauseSubscription = async () => {
    try {
      setIsLoadingPause(true);
      const response = await axios.post(
        `${serverUrl}/stripe/pause-subscription`,
        { pauseDuration },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
          },
        }
      );
      
      if (response.data && response.data.success) {
        toast({
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        
        // Update local state to reflect paused status
        if (user && user.organization) {
          setUser({
            ...user,
            organization: {
              ...user.organization,
              subscriptionDetails: {
                ...user.organization.subscriptionDetails,
                paused: true,
                pausedUntil: new Date(new Date().setMonth(new Date().getMonth() + pauseDuration))
              }
            }
          });
        }
        
        setIsPauseModalOpen(false);
      }
    } catch (error) {
      console.error('Error pausing subscription:', error);
      toast({
        title: 'Error',
        description: error.response?.data?.error || 'Failed to pause subscription. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoadingPause(false);
    }
  };

  const getSubscriptionStatus = () => {
    if (!user || !user.organization || !user.organization.subscriptionDetails) {
      return 'No active subscription';
    }
    
    const { subscriptionDetails } = user.organization;
    
    if (subscriptionDetails.paused) {
      const pausedUntil = new Date(subscriptionDetails.pausedUntil);
      return `Paused until ${pausedUntil.toLocaleDateString()}`;
    }
    
    if (!subscriptionDetails.active) {
      return 'Inactive';
    }
    
    return 'Active';
  };

  return (
    <Box bg="white" p={8}>
      <Heading color={'#00417D'} pb={4}>Settings</Heading>
      <VStack align="start" spacing={4} mt={5}>
        <Box w="100%">
          <Heading color={'#00417D'} fontSize={'24px'} mb={4}>User Settings</Heading>
          <VStack align="start" spacing={4}>
            <HStack justify="space-between" w="40%">
              <HStack>
                <Text>Two-Factor Authentication</Text>
                <Tooltip label="Enable two-factor authentication for additional security">
                  <InfoIcon color="gray.500" />
                </Tooltip>
              </HStack>
              <Switch 
                id="two-factor"
                isChecked={twoFactorEnabled} 
                onChange={handleTwoFactorToggle}
              />
            </HStack>

            {twoFactorEnabled && (
              <HStack w="40%" alignItems="flex-start">
                <FormControl>
                  <FormLabel>Phone Number</FormLabel>
                  <InputGroup>
                    <InputLeftAddon children="+1" />
                    <Input
                      type="tel"
                      placeholder="(555) 555-5555"
                      value={twoFactorPhone}
                      onChange={handlePhoneChange}
                      pattern="\(\d{3}\) \d{3}-\d{4}"
                      maxLength={14}
                      isReadOnly={!isEditingPhone}
                    />
                  </InputGroup>
                  <FormHelperText>
                    {twoFactorPhoneVerified 
                      ? "Phone number verified" 
                      : "Phone number pending verification"}
                  </FormHelperText>
                </FormControl>
                {isEditingPhone ? (
                  <Button 
                    onClick={handlePhoneSubmit} 
                    isDisabled={!twoFactorPhone || twoFactorPhone.length < 14}
                    mt={8}
                  >
                    Save
                  </Button>
                ) : (
                  <Button 
                    onClick={() => setIsEditingPhone(true)}
                    mt={8}
                  >
                    Edit
                  </Button>
                )}
                {!twoFactorPhoneVerified && twoFactorPhone && !isEditingPhone && (
                  <Button
                    colorScheme="blue"
                    mt={8}
                    onClick={handleVerifyPhone}
                  >
                    Verify
                  </Button>
                )}
              </HStack>
            )}

            <HStack justify="space-between" w="40%">
              <HStack>
                <Text>Enable Beta Features</Text>
                {isUserLoading && (
                  <Spinner size="sm" ml={2} />
                )}
              </HStack>
              <Switch
                id="beta-features"
                isChecked={isBetaFeatures}
                onChange={handleBetaToggle}
                isDisabled={isUserLoading}
              />
            </HStack>
            
            <HStack justify="space-between" w="40%">
              <HStack>
                <Text>Use Email Template for Post-Meeting Follow-ups</Text>
                <Tooltip 
                  label={(hasMicrosoftIntegration || hasGoogleIntegration) 
                    ? "Enable custom email template for meeting follow-ups" 
                    : "Google or Microsoft integration required for this feature"}
                >
                  <InfoIcon color="gray.500" />
                </Tooltip>
              </HStack>
              <Switch 
                isChecked={useEmailTemplate} 
                onChange={handleEmailTemplateToggle}
                isDisabled={!isBetaFeatures || (!hasMicrosoftIntegration && !hasGoogleIntegration)}
              />
            </HStack> 
            <EmailTemplateEditor
              useEmailTemplate={useEmailTemplate}
              emailTemplate={emailTemplate}
              handleTemplateChange={handleTemplateChange}
              quillRef={quillRef}
              signatureImage={signatureImage}
              handleSignatureUpload={handleSignatureUpload}
              fileInputRef={fileInputRef}
              defaultTemplate={defaultTemplate}
            />  

            <Box w="100%" mt={8}>
              <Heading color={'#00417D'} fontSize={'24px'} mb={4}>Automatic Meeting Prep</Heading>
              <VStack align="start" spacing={4}>
                <HStack justify="space-between" w="100%">
                  <HStack>
                    <Text>Automatically Email Me With Prep Notes 60 Minutes Before A Client Meeting</Text>
                    <Switch
                      isChecked={emailBeforeMeeting}
                      onChange={() => {
                        setEmailBeforeMeeting(!emailBeforeMeeting);
                        handleToggleChange('emailBeforeMeeting', !emailBeforeMeeting);
                      }}
                    />
                  </HStack>
                </HStack>
                <HStack justify="space-between" w="100%">
                  <HStack>
                    <Text>Automatically Email Me With Prep Notes For All The Day's Meetings at</Text>
                    <Select
                      width="100px"
                      value={localTime}
                      onChange={(e) => {
                        const [hour, minute] = e.target.value.split(':').map(Number);
                        const localDate = new Date();
                        localDate.setHours(hour, minute, 0, 0);

                        // Convert to UTC
                        const utcHour = localDate.getUTCHours().toString().padStart(2, '0');
                        const utcMinute = localDate.getUTCMinutes().toString().padStart(2, '0');
                        const utcTimeValue = `${utcHour}:${utcMinute}`;

                        setLocalTime(e.target.value);
                        setUtcTime(utcTimeValue);
                      }}
                      onBlur={() => handleInputBlur('dailyEmailTime', utcTime)}
                      size="sm"
                      isDisabled={!emailForAllMeetings}
                    >
                      {Array.from({ length: 24 * 12 }, (_, index) => {
                        const hour = Math.floor(index / 12);
                        const minute = (index % 12) * 5;
                        const displayHour = hour.toString().padStart(2, '0');
                        const displayMinute = minute.toString().padStart(2, '0');

                        return (
                          <option key={index} value={`${displayHour}:${displayMinute}`}>
                            {displayHour}:{displayMinute}
                          </option>
                        );
                      })}
                    </Select>
                    <Switch
                      isChecked={emailForAllMeetings}
                      onChange={() => {
                        setEmailForAllMeetings(!emailForAllMeetings);
                        handleToggleChange('emailForAllMeetings', !emailForAllMeetings);
                      }}
                    />
                  </HStack>
                </HStack>
              </VStack>
            </Box>

            {/* TODO: Need to test more thoroughly before reenabling Write Emails in your Voice feature */}
            {/* <HStack justify="space-between" w="40%">
              <HStack>
                <Text>Write Emails in your Voice</Text>
                <Tooltip 
                  label={(hasMicrosoftIntegration || hasGoogleIntegration) 
                    ? "Enable AI to write emails matching your writing style" 
                    : "Google or Microsoft integration required for this feature"}
                >
                  <InfoIcon color="gray.500" />
                </Tooltip>
              </HStack>
              <Switch 
                isChecked={useEmailVoice} 
                onChange={handleEmailVoiceToggle}
                isDisabled={!isBetaFeatures || (!hasMicrosoftIntegration && !hasGoogleIntegration)}
              />
            </HStack>
            <EmailComparison
              standardEmail={standardEmail}
              voicedEmail={voicedEmail}
              useEmailVoice={useEmailVoice}
            /> 

            <HStack justify="space-between" w="40%">
              <HStack>
                <Text>AI Insights</Text>
                <Tooltip label="Enable AI-powered insights and analytics">
                  <InfoIcon color="gray.500" />
                </Tooltip>
              </HStack>
              <Switch
                id="ai-insights"
                isChecked={aiInsightsEnabled}
                onChange={handleAiInsightsToggle}
                isDisabled={!isBetaFeatures}
              />
            </HStack>
            */}
          </VStack>
        </Box>

        {isAdmin() && (
          <>
            {/* <Box w="100%" mt={8}> */}
              
              {/* <Heading color={'#00417D'} fontSize={'24px'} mb={4}>Organization Settings</Heading> */}
              {/* <VStack align="start" spacing={4}> */}
                {/* <HStack justify="space-between" w="40%">
                  <HStack>
                    <Text color={isBetaFeatures ? "black" : "gray.500"}>
                      Allow DataDasher to Generate CRM Field Updates
                    </Text>
                    <Tooltip label="Automatically update CRM fields using AI">
                      <InfoIcon color="gray.500" />
                    </Tooltip>
                  </HStack>
                  <Switch
                    id="manual-task-assignment"
                    isChecked={!manuallyAssignTasks}
                    onChange={handleManualTaskAssignmentChange}
                    isDisabled={!isBetaFeatures}
                  />
                </HStack>
                
                <HStack justify="space-between" w="40%">
                  <HStack>
                    <Text color={isBetaFeatures ? "black" : "gray.500"}>
                      Allow Assigning Tasks to DataDasher
                    </Text>
                    <Tooltip label="Enable task assignment to AI assistant">
                      <InfoIcon color="gray.500" />
                    </Tooltip>
                  </HStack>
                  <Switch
                    id="enable-datadasher-assignment"
                    isChecked={enableAssignToDataDasher}
                    onChange={handleDataDasherAssignmentChange}
                    isDisabled={!isBetaFeatures}
                  />
                </HStack> */}
              {/* </VStack> */}
            {/* </Box> */}

            <Box w="100%" mt={8}>
              <Heading color={'#00417D'} fontSize={'24px'} mb={4}>Organization Members</Heading>
              <HStack mb={4}>
                <Select 
                  width="200px" 
                  value={userFilter} 
                  onChange={(e) => setUserFilter(e.target.value)}
                >
                  <option value="all">All Members</option>
                  <option value="pending">Pending</option>
                  <option value="active">Active</option>
                  <option value="declined">Declined</option>
                </Select>
              </HStack>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Email</Th>
                    <Th>Role</Th>
                    <Th>Status</Th>
                    <Th>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {getFilteredUsers().map((localuser) => (
                    <Tr key={localuser.id}>
                      <Td>{`${localuser.firstName} ${localuser.lastName}`}</Td>
                      <Td>{localuser.email}</Td>
                      <Td>{localuser.role}</Td>
                      <Td>
                        <Badge
                          colorScheme={
                            localuser.inviteStatus === 'declined' ? 'red' :
                            localuser.inviteStatus === 'active' ? 'green' : 'yellow'
                          }
                        >
                          {getUserStatus(localuser).toUpperCase()}
                        </Badge>
                      </Td>
                      <Td>
                        <HStack spacing={2}>
                          {(localuser.inviteStatus === 'pending') && (
                            <>
                              <Button
                                size="sm"
                                colorScheme="blue"
                                onClick={() => handleUserStatus(localuser.id, 'active')}
                              >
                                Approve
                              </Button>
                              <Button
                                size="sm"
                                colorScheme="red"
                                onClick={() => handleUserStatus(localuser.id, 'declined')}
                              >
                                Reject
                              </Button>
                            </>
                          )}
                          {(localuser.inviteStatus === 'active' && localuser.id !== user.id) && (
                            <Button
                              size="sm"
                              colorScheme="red"
                              onClick={() => {
                                setUserToRemove(localuser);
                                setIsRemoveDialogOpen(true);
                              }}
                            >
                              Remove
                            </Button>
                          )}
                        </HStack>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <Button
                mt={4}
                colorScheme="blue"
                onClick={handleInviteColleagues}
              >
                Invite Colleagues
              </Button>
            </Box>
          </>
        )}

        {isAdmin() && (
          <Box mt={8} p={4} borderWidth="1px" borderRadius="lg">
            <Heading size="md" mb={4}>Billing</Heading>
            
            <VStack align="start" spacing={4} width="100%">
              <HStack width="100%" justifyContent="space-between">
                <Text fontWeight="bold">Current Plan:</Text>
                <Text>
                  {user?.organization?.subscriptionDetails?.plan || 'Standard'}
                </Text>
              </HStack>
              
              <HStack width="100%" justifyContent="space-between">
                <Text fontWeight="bold">Status:</Text>
                <Badge colorScheme={
                  user?.organization?.subscriptionDetails?.paused ? 'yellow' :
                  user?.organization?.subscriptionDetails?.active ? 'green' : 'red'
                }>
                  {getSubscriptionStatus()}
                </Badge>
              </HStack>
              
              <HStack width="100%" justifyContent="space-between">
                <Text fontWeight="bold">Active Users:</Text>
                <Text>
                  {user?.organization?.subscriptionDetails?.seatsStandard || 1}
                </Text>
              </HStack>
              
              <HStack width="100%" spacing={4}>
                <Button
                  colorScheme="blue"
                  onClick={handleManageSubscription}
                  isLoading={isLoadingPortal}
                  isDisabled={!user?.organization?.stripeCustomerId}
                >
                  Manage Subscription
                </Button>
                
                <Button
                  colorScheme="yellow"
                  onClick={() => setIsPauseModalOpen(true)}
                  isDisabled={
                    !user?.organization?.subscriptionDetails?.active ||
                    user?.organization?.subscriptionDetails?.paused ||
                    !user?.organization?.stripeCustomerId
                  }
                >
                  Pause Subscription
                </Button>
              </HStack>
            </VStack>
          </Box>
        )}

        <Box w="100%" mt={8} mb={8}>
          <Heading color={'#00417D'} fontSize={'24px'} mb={4}>Danger Zone</Heading>
          <Button
            colorScheme="red"
            onClick={onOpen}
          >
            Delete Account
          </Button>
        </Box>

        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Account
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure? This will permanently delete your account and all associated data. This action cannot be undone.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button 
                  colorScheme="red" 
                  onClick={() => {
                    handleDeleteAccount();
                    onClose();
                  }} 
                  ml={3}
                >
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>

        <AlertDialog
          isOpen={isRemoveDialogOpen}
          leastDestructiveRef={cancelRef}
          onClose={() => setIsRemoveDialogOpen(false)}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Remove User
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure you want to remove {userToRemove?.firstName} {userToRemove?.lastName} from the organization? This action cannot be undone.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={() => setIsRemoveDialogOpen(false)}>
                  Cancel
                </Button>
                <Button 
                  colorScheme="red" 
                  onClick={() => handleRemoveUser(userToRemove?.id)} 
                  ml={3}
                >
                  Remove
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>

        <Modal isOpen={isPauseModalOpen} onClose={() => setIsPauseModalOpen(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Pause Subscription</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text mb={4}>
                Are you sure you want to pause your subscription? Your subscription will be paused immediately.
              </Text>
              <FormControl>
                <FormLabel>Pause Duration</FormLabel>
                <Select 
                  value={pauseDuration} 
                  onChange={(e) => setPauseDuration(Number(e.target.value))}
                >
                  <option value={1}>1 month</option>
                  <option value={2}>2 months</option>
                  <option value={3}>3 months</option>
                </Select>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={() => setIsPauseModalOpen(false)}>
                Cancel
              </Button>
              <Button 
                colorScheme="yellow" 
                onClick={handlePauseSubscription}
                isLoading={isLoadingPause}
              >
                Confirm
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </VStack>
    </Box>
  );
};

if (typeof window !== 'undefined') {
  const Quill = ReactQuill.Quill;
  const LineBreak = Quill.import('blots/break');
  
  class CustomLineBreak extends LineBreak {
    length() {
      return 1;
    }
    value() {
      return '\n';
    }
  }
  
  CustomLineBreak.blotName = 'linebreak';
  CustomLineBreak.tagName = 'BR';
  
  Quill.register(CustomLineBreak, true);
}

export default Settings;