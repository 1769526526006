import React, { useState, useRef, useEffect } from 'react';
import {
  Box, Grid, useToast, Tabs, TabList, TabPanels, TabPanel, Tab,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody,
  Skeleton, SkeletonText, HStack, VStack, Heading, IconButton
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import useCorrespondence from '../../../hooks/useCorrespondence';
import { useUser } from '../../../contexts/UserContext';
import { useOrganizationUsers } from '../../../contexts/OrganizationUsersContext';
import { useOrganizationClients } from '../../../contexts/OrganizationClientsContext';

import TranscriptionPanel from './correspondence/TranscriptionPanel';
import SummaryPanel from './correspondence/SummaryPanel';
import ActionItemsPanel from './correspondence/ActionItemsPanel';
import InsightsPanel from './correspondence/InsightsPanel';
import ChatPanel from './correspondence/ChatPanel';
import FollowUpEmail from './components/FollowUpEmail';
import AudioPlayer from '../../AudioPlayer';
import useAudioPlayer from '../../../hooks/useAudioPlayer';
import { MdFullscreen, MdFullscreenExit } from 'react-icons/md';
/**
 * Container component for the correspondence page
 */
const CorrespondenceContainer = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { user } = useUser();
  const { users } = useOrganizationUsers();
  const { clients } = useOrganizationClients();
  const audioRef = useRef(null);

  // State from useCorrespondence hook
  const {
    callData,
    groupedTranscription,
    actionItems,
    summary,
    aiInsights,
    isLoading,
    isLoadingTranscription,
    isLoadingActionItems,
    isLoadingInsights,
    isLoadingQuestions,
    isEditingTitle,
    isEditingSummary,
    isEditingTranscription,
    handleEditTitle,
    handleCancelEditTitle,
    handleSaveTitle,
    handleEditSummary,
    handleCancelEditSummary,
    handleSaveSummary,
    handleEditTranscription,
    handleCancelEditTranscription,
    handleSaveTranscription,
    handleTranscriptionChange,
    handleSpeakerUpdate,
    handleApproveActionItem,
    handleRejectActionItem,
    handleAssignActionItem,
    handleDateChange,
    handleSendMessage,
    handleAskAboutSubmit,
    fetchData,
    questions,
    currentPage,
    totalPages,
    totalItems,
    isLoadingMoreTranscription,
    loadMoreTranscription,
    allTranscriptionLoaded,
    activeTab,
    setActiveTab,
    crm
  } = useCorrespondence();

  // Extract specific data from callData for better organization
  const data = callData?.call;
  const id = callData?.call?.id;
  const title = callData?.call?.title;
  const audioUrl = callData?.call?.audio_url;
  const transcription = callData?.call?.transcription;
  const topics = callData?.call?.topics;
  const clientTodos = callData?.call?.clientTodos;
  const advisorTodos = callData?.call?.advisorTodos;
  const date = callData?.call?.startTime || callData?.call?.receivedAt;

  // Initialize audio element
  useEffect(() => {
    if (!audioRef.current && audioUrl) {
      audioRef.current = new Audio(audioUrl);
    }
  }, [audioUrl]);

  // Initialize audio player
  const {
    isPlaying,
    currentTime,
    duration,
    progress,
    isBuffering,
    handlePlayPause,
    handleSkip,
    handleSeek,
    jumpToTimestamp
  } = useAudioPlayer({
    audioUrl: audioUrl,
    transcription: transcription,
    audioElement: audioRef.current
  });

  // Extract more specific data from callData for better organization
  const stats = callData?.call?.stats;
  const type = callData?.call?.type || 'call';
  const metadata = callData?.call?.metadata;

  // UI state
  const [isFollowUpModalOpen, setIsFollowUpModalOpen] = useState(false);
  const [isTasksModalOpen, setIsTasksModalOpen] = useState(false);
  const [isLeftExpanded, setIsLeftExpanded] = useState(false);
  const [isRightExpanded, setIsRightExpanded] = useState(false);
  const [leftPanelWidth, setLeftPanelWidth] = useState(50);
  const [isDragging, setIsDragging] = useState(false);

  // Handle tab change
  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  // Toggle follow-up modal
  const handleToggleFollowUpModal = () => {
    setIsFollowUpModalOpen(!isFollowUpModalOpen);
  };

  // Toggle tasks modal
  const handleToggleTasksModal = () => {
    setIsTasksModalOpen(!isTasksModalOpen);
  };

  // Get grid template based on expanded panels
  const getGridTemplate = (isLeftExpanded, isRightExpanded) => {
    if (isLeftExpanded && !isRightExpanded) {
      return "1fr 0fr";
    } else if (!isLeftExpanded && isRightExpanded) {
      return "0fr 1fr";
    } else if (isLeftExpanded && isRightExpanded) {
      return "1fr 1fr";
    } else {
      return "1fr 1fr";
    }
  };

  // Skeleton components for loading states
  const TranscriptionSkeleton = () => (
    <Box bg="white" borderRadius="md" shadow="sm" p={4} h="100%" overflowY="auto">
      <Skeleton height="40px" width="70%" mb={4} />
      <SkeletonText mt={2} noOfLines={1} spacing="4" width="30%" />
      <Box mt={6}>
        {[...Array(8)].map((_, i) => (
          <Box key={i} mb={6}>
            <Skeleton height="20px" width="120px" mb={2} />
            <SkeletonText mt={2} noOfLines={3} spacing="4" />
          </Box>
        ))}
      </Box>
    </Box>
  );

  const SummarySkeleton = () => (
    <Box>
      <Skeleton height="40px" width="60%" mb={4} />
      <SkeletonText mt={4} noOfLines={6} spacing="4" />
      <Box mt={8}>
        <Skeleton height="30px" width="40%" mb={4} />
        <Box mt={4}>
          {[...Array(3)].map((_, i) => (
            <Skeleton key={i} height="30px" width="90%" mb={3} />
          ))}
        </Box>
      </Box>
    </Box>
  );

  const ActionItemsSkeleton = () => (
    <Box>
      <Skeleton height="30px" width="40%" mb={4} />
      {[...Array(3)].map((_, i) => (
        <Box key={i} p={4} mb={4} borderWidth="1px" borderRadius="md">
          <Skeleton height="24px" width="80%" mb={3} />
          <HStack spacing={4} mt={3}>
            <Skeleton height="36px" width="120px" />
            <Skeleton height="36px" width="120px" />
            <Skeleton height="36px" width="100px" />
          </HStack>
        </Box>
      ))}
    </Box>
  );

  const InsightsSkeleton = () => (
    <Box>
      <Skeleton height="30px" width="40%" mb={4} />
      {[...Array(4)].map((_, i) => (
        <Box key={i} p={4} mb={4} borderWidth="1px" borderRadius="md">
          <Skeleton height="24px" width="60%" mb={3} />
          <SkeletonText mt={2} noOfLines={2} spacing="4" />
        </Box>
      ))}
    </Box>
  );

  const ChatSkeleton = () => (
    <Box>
      <Skeleton height="30px" width="40%" mb={4} />
      <Box mt={4} mb={8}>
        {[...Array(3)].map((_, i) => (
          <Box
            key={i}
            p={4}
            mb={4}
            borderWidth="1px"
            borderRadius="md"
            alignSelf={i % 2 === 0 ? "flex-end" : "flex-start"}
            maxW="80%"
            ml={i % 2 === 0 ? "auto" : 0}
          >
            <Skeleton height="20px" width="100px" mb={2} />
            <SkeletonText mt={2} noOfLines={2} spacing="4" />
          </Box>
        ))}
      </Box>
      <Skeleton height="50px" width="100%" mt={4} />
    </Box>
  );

  // If the entire page is loading, show a full-page skeleton
  if (isLoading && !callData) {
    return (
      <Box position="relative" bg="gray.50" minH="100vh">
        <Box pb={audioUrl ? "100px" : "4"}>
          <Grid
            templateColumns="1fr 1fr"
            gap={4}
            h={audioUrl ? "calc(100vh - 280px)" : "calc(100vh - 200px)"}
          >
            <TranscriptionSkeleton />
            <Box bg="white" borderRadius="md" shadow="sm" p={4}>
              <Skeleton height="40px" mb={4} />
              <SummarySkeleton />
            </Box>
          </Grid>
        </Box>

        {/* Spacer to prevent content from being hidden behind the audio player */}
        {audioUrl && (
          <Box
            height="80px"
            width="100%"
          />
        )}
      </Box>
    );
  }

  const handleDragStart = () => {
    setIsDragging(true);
    document.body.style.cursor = 'col-resize';
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    document.body.style.cursor = 'default';
  };

  const handleDrag = (e) => {
    if (!isDragging) return;

    const container = e.currentTarget.parentElement;
    const containerRect = container.getBoundingClientRect();
    const containerWidth = containerRect.width;
    const offsetX = e.clientX - containerRect.left;
    const newWidth = (offsetX / containerWidth) * 100;

    // Limit the resize range (20% to 80%)
    if (newWidth >= 20 && newWidth <= 80) {
      setLeftPanelWidth(newWidth);
    }
  };

  return (
    <Box position="relative" bg="gray.50" height="100vh" overflow="hidden">
      <Box p={0} height={audioUrl ? "calc(100vh - 80px)" : "100vh"} overflow="hidden">
        <Grid
          templateColumns={
            isLeftExpanded
              ? "1fr"
              : isRightExpanded
                ? "1fr"
                : `${leftPanelWidth}% 8px calc(${100 - leftPanelWidth}% - 8px)`
          }
          gap={0}
          height="100%"
          maxHeight="calc(100vh - 82px)"
          overflowY="hidden"
          bg="#F5F5F5"
          onMouseMove={handleDrag}
          onMouseUp={handleDragEnd}
          onMouseLeave={handleDragEnd}
        >
          {/* Left panel - Transcription */}
          {isLoadingTranscription ? (
            <TranscriptionSkeleton />
          ) : (
            <TranscriptionPanel
              callData={callData}
              callId={id}
              callTitle={title}
              callAudioUrl={audioUrl}
              callTranscription={transcription || []}
              groupedTranscription={groupedTranscription || []}
              isEditingTranscription={isEditingTranscription}
              handleEditTranscription={handleEditTranscription}
              handleCancelEditTranscription={handleCancelEditTranscription}
              handleSaveTranscription={handleSaveTranscription}
              handleTranscriptionChange={handleTranscriptionChange}
              handleSpeakerUpdate={handleSpeakerUpdate}
              isLeftExpanded={isLeftExpanded}
              setIsLeftExpanded={setIsLeftExpanded}
              isRightExpanded={isRightExpanded}
              setIsRightExpanded={setIsRightExpanded}
              // Audio state
              isPlaying={isPlaying}
              currentTime={currentTime}
              duration={duration}
              progress={progress}
              isBuffering={isBuffering}
              handlePlayPause={handlePlayPause}
              handleSkip={handleSkip}
              handleSeek={handleSeek}
              jumpToTimestamp={jumpToTimestamp}
              audioRef={audioRef}
              // Pagination props
              currentPage={currentPage}
              totalPages={totalPages}
              totalItems={totalItems || 0}
              isLoadingMoreTranscription={isLoadingMoreTranscription || false}
              loadMoreTranscription={loadMoreTranscription || (() => {})}
              allTranscriptionLoaded={allTranscriptionLoaded || false}
            />
          )}

          {/* Resizer */}
          {!isLeftExpanded && !isRightExpanded && (
            <Box
              gridColumn="2 / 3"
              bg="gray.100"
              cursor="col-resize"
              onMouseDown={handleDragStart}
              position="relative"
              _hover={{ bg: "blue.100" }}
              transition="background 0.2s"
            >
              <Box
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                width="4px"
                height="32px"
                borderRadius="full"
                bg={isDragging ? "blue.500" : "gray.300"}
                transition="background 0.2s"
              />
            </Box>
          )}

          {/* Right Panel */}
          {!isLeftExpanded && (
            <Box
              gridColumn={isRightExpanded ? "1 / -1" : "3 / -1"}
              bg="white"
              position="relative"
              transition="width 0.1s ease"
              overflow="hidden"
              display="flex"
              flexDirection="column"
              height="100%"
            >
              <Box
                p={4}
                bg="gray.50"
                borderColor="gray.200"
              >
                <HStack justify="space-between">
                  <Heading size="md" color="#00417D">Meeting Details</Heading>
                  <IconButton
                    icon={isRightExpanded ? <MdFullscreenExit /> : <MdFullscreen />}
                    onClick={() => {
                      setIsRightExpanded(!isRightExpanded);
                      if (!isRightExpanded) {
                        setIsLeftExpanded(false);
                      }
                    }}
                    aria-label="Toggle fullscreen"
                  />
                </HStack>
              </Box>
              <Tabs 
                index={activeTab} 
                onChange={handleTabChange} 
                variant="enclosed" 
                display="flex" 
                flexDirection="column" 
                height="calc(100% - 72px)" 
                overflow="hidden"
                isLazy={true}
              >
                <TabList>
                  <Tab>Outline</Tab>
                  <Tab>Action Items</Tab>
                  {/*
                  <Tab>Insights</Tab>
                  */}
                  <Tab>Ask About</Tab>
                  <Tab>Follow-Up Email</Tab>
                </TabList>

                <TabPanels flex="1" overflow="hidden">
                  {/* Summary Tab */}
                  <TabPanel height="100%" overflow="hidden">
                    {isLoading ? (
                      <SummarySkeleton />
                    ) : (
                      <SummaryPanel
                        data={data}
                        id={id}
                        title={title}
                        summary={summary}
                        type={'call'}
                        isEditingSummary={isEditingSummary}
                        handleEditSummary={handleEditSummary}
                        handleCancelEditSummary={handleCancelEditSummary}
                        handleSaveSummary={handleSaveSummary}
                        handleToggleFollowUpModal={handleToggleFollowUpModal}
                      />
                    )}
                  </TabPanel>

                  {/* Action Items Tab */}
                  <TabPanel height="100%" overflow="hidden">
                    {isLoadingActionItems ? (
                      <ActionItemsSkeleton />
                    ) : (
                      <ActionItemsPanel
                        actionItems={actionItems}
                        id={id}
                        users={crm?.crmUsers || users}
                        handleApproveActionItem={handleApproveActionItem}
                        handleRejectActionItem={handleRejectActionItem}
                        handleAssignActionItem={handleAssignActionItem}
                        handleDateChange={handleDateChange}
                        handleToggleTasksModal={handleToggleTasksModal}
                        data={data}
                        crm={crm}
                      />
                    )}
                  </TabPanel>

                  {/* Insights Tab */}
                  {/*
                  <TabPanel height="100%" overflow="hidden">
                    {isLoadingInsights ? (
                      <InsightsSkeleton />
                    ) : (
                      <InsightsPanel
                        insights={aiInsights}
                        data={data}
                        id={id}
                        stats={stats}
                      />
                    )}
                  </TabPanel>
                  */}

                  {/* Chat Tab */}
                  <TabPanel height="100%" overflow="hidden">
                    {isLoadingQuestions ? (
                      <ChatSkeleton />
                    ) : (
                      <ChatPanel
                        data={data}
                        id={id}
                        transcription={transcription}
                        questions={questions}
                        handleSendMessage={handleSendMessage}
                        handleAskAboutSubmit={handleAskAboutSubmit}
                      />
                    )}
                  </TabPanel>
                  
                  {/* Follow-Up Email Tab */}
                  <TabPanel height="100%" overflow="hidden">
                    <FollowUpEmail
                      callData={callData}
                      callId={id}
                      callTitle={title}
                      callSummary={summary}
                      callClientTodos={clientTodos}
                      callAdvisorTodos={advisorTodos}
                      callClients={clients}
                      onClose={() => {}}
                      type={'call'}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          )}
        </Grid>
      </Box>

      {/* Follow-up Email Modal */}
      <Modal isOpen={isFollowUpModalOpen} onClose={handleToggleFollowUpModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Send Follow-up Email</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FollowUpEmail
              data={data}
              id={id}
              title={title}
              summary={summary}
              clientTodos={clientTodos}
              advisorTodos={advisorTodos}
              clients={clients}
              onClose={handleToggleFollowUpModal}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Tasks Modal */}
      <Modal isOpen={isTasksModalOpen} onClose={handleToggleTasksModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Manage Tasks</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <ActionItemsPanel
              actionItems={actionItems}
              id={id}
              users={crm?.crmUsers || users}
              handleApproveActionItem={handleApproveActionItem}
              handleRejectActionItem={handleRejectActionItem}
              handleAssignActionItem={handleAssignActionItem}
              handleDateChange={handleDateChange}
              isModal={true}
              data={data}
              crm={crm}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Fixed Audio Controls */}
      {audioUrl && (
        <Box
          position="fixed"
          bottom={0}
          left={0}
          right={0}
          bg="white"
          boxShadow="0 -2px 10px rgba(0, 0, 0, 0.1)"
          p={3}
          zIndex={1000}
        >
          <AudioPlayer
            isPlaying={isPlaying}
            currentTime={currentTime}
            duration={duration}
            progress={progress}
            isBuffering={isBuffering}
            onPlayPause={handlePlayPause}
            onSkip={handleSkip}
            onSeek={handleSeek}
          />
        </Box>
      )}

      {/* Hidden audio element */}
      <audio
        ref={audioRef}
        style={{ display: 'none' }}
        src={audioUrl}
      />
    </Box>
  );
};

export default CorrespondenceContainer; 