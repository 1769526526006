import React, { useState, useEffect } from 'react';
import { Box, Collapse, IconButton, Text, useDisclosure, useToast, HStack, VStack, Button, Input, OrderedList, ListItem, Icon, Tooltip, Menu, MenuButton, MenuList, MenuItem, Link, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Badge, Spinner, Wrap, WrapItem } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon, ViewOffIcon, ViewIcon, PhoneIcon, EmailIcon, TimeIcon, WarningIcon, CheckIcon, CloseIcon, EditIcon, SaveIcon } from '@chakra-ui/icons';
import { FaUser, FaEdit, FaInfo, FaRegEnvelope, FaSync, FaCheckCircle, FaInfoCircle, FaSave, FaEnvelope, FaInbox, FaArchive, FaRegCalendarCheck } from 'react-icons/fa';
import BlueBox from './BlueBox';
import AssignClientButton from './AssignClientButton';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useOrganizationClients } from '../contexts/OrganizationClientsContext';
import PropTypes from 'prop-types';
import { FaPhone } from 'react-icons/fa';
import { useUser } from '../contexts/UserContext';
import { FeatureStats } from '../enums/UsageStats';

const SUMMARY_CHARACTER_LIMIT = 300; // Show ~3 lines of text

const EditTitleInput = ({ value, onChange, onSave, onCancel }) => (
  <VStack align="start" spacing={1} width="100%">
    <Input
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder="Enter new title"
      size="sm"
    />
    <HStack>
      <Button size="sm" colorScheme="green" onClick={onSave}>
        Save
      </Button>
      <Button size="sm" colorScheme="red" onClick={onCancel}>
        Cancel
      </Button>
    </HStack>
  </VStack>
);

const TitleDisplay = ({ title, date, isEditable, onEdit, isHovered }) => (
  <HStack 
    flex={1} 
    spacing={2}
    role="group"
    justifyContent="space-between"
    width="100%"
  >
    <HStack spacing={2}>
      <Text fontSize="lg" fontWeight="bold">
        {title || 'Untitled'}
      </Text>
      {isEditable && (
        <IconButton
          aria-label="Edit Title"
          icon={<FaEdit />}
          size="sm"
          onClick={onEdit}
          variant="ghost"
          opacity={isHovered ? 1 : 0}
          transition="opacity 0.3s"
          _groupHover={{ opacity: 1 }}
        />
      )}
    </HStack>
    
    {date && (
      <HStack spacing={1} color="gray.600" fontSize="sm">
        <TimeIcon boxSize={3} />
        <Text>{date}</Text>
      </HStack>
    )}
  </HStack>
);

const MetadataDisplay = ({ emailData, client, replyCount }) => (
  <HStack spacing={4} color="gray.600" fontSize="sm" width="100%">
    {emailData?.emailAddress && (
      <HStack spacing={1}>
        <EmailIcon boxSize={3} />
        <Tooltip label={emailData.emailAddress.address}>
          <Text>{emailData.emailAddress.name}</Text>
        </Tooltip>
        {replyCount > 0 && (
          <Badge 
            ml={2} 
            colorScheme="blue" 
            variant="solid" 
            borderRadius="full"
            fontSize="xs"
          >
            {replyCount} {replyCount === 1 ? 'reply' : 'replies'}
          </Badge>
        )}
      </HStack>
    )}
    {client?.length > 0 && (
      <HStack spacing={1}>
        <Icon as={FaUser} boxSize={3} />
        {client.map((c, index) => (
          <Link 
            key={index}
            as={RouterLink} 
            to={`/clients/${c.id}`}
            color="#00417D"
            _hover={{
              textDecoration: 'underline',
              color: '#00345B'
            }}
          >
            <Text>
              {`${c.firstName} ${c.lastName}${index < client.length - 1 ? ',' : ''}`}
            </Text>
          </Link>
        ))}
      </HStack>
    )}
  </HStack>
);

const ActionButtons = ({ type, onViewContent, onArchive, onFollowUp, isArchived, hasClient }) => (
  <Wrap spacing={2} mt={2} justify="flex-start">
    <WrapItem>
      <Button
        size="sm"
        colorScheme="blue"
        variant="outline"
        leftIcon={type === "email" ? <FaEnvelope /> : <FaPhone />}
        onClick={onViewContent}
        whiteSpace="normal"
        textAlign="left"
        height="auto"
        py={1.5}
        minW="auto"
      >
        {type === "email" ? "View Email" : "View Call"}
      </Button>
    </WrapItem>
    <WrapItem>
      <Button
        size="sm"
        leftIcon={<FaInfoCircle />}
        variant="outline"
        colorScheme="blue"
        onClick={() => {}}
        whiteSpace="normal"
        textAlign="left"
        height="auto"
        py={1.5}
        minW="auto"
      >
        Insights
      </Button>
    </WrapItem>
    <WrapItem>
      <Button
        size="sm"
        leftIcon={<FaRegCalendarCheck />}
        variant="outline"
        colorScheme="blue"
        onClick={onFollowUp}
        isDisabled={!hasClient}
        whiteSpace="normal"
        textAlign="left"
        height="auto"
        py={1.5}
        minW="auto"
      >
        Follow Up
      </Button>
    </WrapItem>
    <WrapItem ml="auto">
      <Button
        size="sm"
        leftIcon={isArchived ? <FaInbox /> : <FaArchive />}
        colorScheme={isArchived ? "green" : "gray"}
        variant="outline"
        onClick={onArchive}
        whiteSpace="normal"
        textAlign="left"
        height="auto"
        py={1.5}
        minW="auto"
      >
        {isArchived ? "Unarchive" : "Archive"}
      </Button>
    </WrapItem>
  </Wrap>
);

const getTotalTextLength = (items) => {
  return items.reduce((total, item) => total + item.length, 0);
};

const ClientSection = ({ clients, stateClient, onClientAssign, isLoading }) => (
  <HStack 
    spacing={2} 
    p={2} 
    borderRadius="md" 
    bg="gray.50"
    borderWidth="1px"
    borderColor="gray.200"
    w="100%"
  >
    <Icon as={FaUser} boxSize={3} color="gray.500" />
    {stateClient?.length > 0 ? (
      <HStack justify="space-between" flex={1}>
        <HStack spacing={1}>
          {stateClient.map((c, index) => (
            <Link 
              key={index}
              as={RouterLink} 
              to={`/clients/${c.id}`}
              color="blue.600"
              _hover={{
                textDecoration: 'underline',
                color: 'blue.700'
              }}
            >
              <Text>
                {`${c.firstName} ${c.lastName}${index < stateClient.length - 1 ? ',' : ''}`}
              </Text>
            </Link>
          ))}
        </HStack>
        <Button
          size="xs"
          variant="ghost"
          leftIcon={<FaEdit />}
          onClick={onClientAssign}
          color="gray.600"
          _hover={{ color: 'blue.500' }}
        >
          Reassign
        </Button>
      </HStack>
    ) : (
      <HStack justify="space-between" flex={1}>
        <Text color="gray.500" fontSize="sm">No client assigned</Text>
        <Button
          size="xs"
          colorScheme="blue"
          variant="solid"
          leftIcon={<FaUser />}
          onClick={onClientAssign}
          isLoading={isLoading}
        >
          Assign Client
        </Button>
      </HStack>
    )}
  </HStack>
);

// Helper function to format category name
const formatCategoryName = (categoryKey) => {
  return categoryKey
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.toLowerCase().slice(1))
    .join(' ');
};

const GrayBox = ({ 
  aiInsights,
  title,
  subtitle,
  metadata = {},
  listItems = [],
  rows = [],
  transcriptId,
  client = [],
  id,
  type,
  scrollRef,
  onArchive,
  errorItems = [],
  correspondence = {},
  nonClientSpeakers = [],
  crm = null,
  onInsightsUpdate,
}) => {
  const toast = useToast();
  const { isOpen, onToggle, onClose } = useDisclosure({ defaultIsOpen: false });
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [newTitle, setNewTitle] = useState(title);
  const [searchQuery, setSearchQuery] = useState('');
  const [showErrorTooltip, setShowErrorTooltip] = useState(false);
  const [isHoveredTitle, setIsHoveredTitle] = useState(false);
  const { clients, loading: clientsLoading } = useOrganizationClients();
  const navigate = useNavigate();
  const serverUrl = process.env.REACT_APP_API_URL;

  const [stateTitle, setStateTitle] = useState(title);
  const [stateClient, setStateClient] = useState(client);
  const [stateItems, setStateItems] = useState(listItems);
  const [stateRows, setStateRows] = useState(rows);
  const [stateCorrespondence, setStateCorrespondence] = useState(correspondence);
  const [showClientSelection, setShowClientSelection] = useState(false);

  const [assignedUsers, setAssignedUsers] = useState({});
  const [editableDates, setEditableDates] = useState({});
  const { user } = useUser();
  const isBetaFeaturesEnabled = user?.betaFeaturesEnabled || false;
  const [userCrms] = useState(user?.organization?.crms[0]?.crmUsers || []);
  const hasWealthboxConnected = user?.organization?.crms?.some(crm => crm.name === 'Wealthbox');
  const hasRedtailConnected = user?.organization?.crms?.some(crm => crm.name.toLowerCase() === 'redtail');

  const [isEditing, setIsEditing] = useState({});
  const [isSaving, setIsSaving] = useState({});

  const canApproveAll = stateRows
    .filter(item => item.aiCompletionStatus === 'PENDING')
    .every(item => assignedUsers[item.id]);

  const [isExpandedSummary, setIsExpandedSummary] = useState(false);

  const [editableNames, setEditableNames] = useState({});
  const [editableDescriptions, setEditableDescriptions] = useState({});

  const [loadingStates, setLoadingStates] = useState({});

  const [approveAllLoading, setApproveAllLoading] = useState(false);

  const [showAIInsights, setShowAIInsights] = useState(false);

  useEffect(() => {
    setStateTitle(title);
    setStateItems(listItems);
    setStateRows(rows);
    setStateCorrespondence(correspondence);
    setStateClient(client);
  }, [title, listItems, rows, correspondence, client]);


  const formatDate = (date) => {
    return new Date(date).toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  };

  const getCorrespondenceStyle = () => {
    if (type === 'call') {
      return {
        icon: PhoneIcon,
        bg: 'blue.50',
        color: 'blue.500'
      };
    }
    return {
      icon: EmailIcon,
      bg: 'green.50',
      color: 'green.500'
    };
  };

  const handleEditTitle = () => {
    setIsEditingTitle(true);
  };

  const handleCancelEditTitle = () => {
    setIsEditingTitle(false);
    setNewTitle(stateTitle);
  };

  const handleSaveTitle = async () => {
    if (newTitle.trim() === '') {
      toast({
        title: "Validation Error",
        description: "Title cannot be empty.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      // Log transcription title edit
      await axios.post(`${serverUrl}/api/usage/log`, {
        statPath: FeatureStats.TRANSCRIPTION_TITLES_EDITED,
        value: 1
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      });

      const response = await axios.put(
        `${serverUrl}/update-title/${id}`,
        { title: newTitle },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtToken")}`,
          },
        }
      );
      setStateTitle(response.data.call.title);
      setIsEditingTitle(false);
      toast({
        title: "Title Updated",
        description: "The call title has been successfully updated.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating title:", error);
      toast({
        title: "Update Failed",
        description: "There was an error updating the title.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleMouseEnterTitle = () => setIsHoveredTitle(true);
  const handleMouseLeaveTitle = () => setIsHoveredTitle(false);
  const handleMouseEnter = () => setShowErrorTooltip(true);
  const handleMouseLeave = () => setShowErrorTooltip(false);

  const handleClientSelect = async (selectedClients) => {
    try {
      const serverUrl = process.env.REACT_APP_API_URL || '';
      let response;

      // If selectedClients is an array, use the /assign endpoint
      if (Array.isArray(selectedClients)) {
        response = await axios.put(
          `${serverUrl}/api/calls/${id}/assign`,
          { clientIds: selectedClients.map(client => client.id) },
          { headers: { Authorization: `Bearer ${Cookies.get("jwtToken")}` } }
        );

        // Update state with all selected clients
        setStateClient(selectedClients);
        setStateCorrespondence(prev => ({ ...prev, clients: selectedClients }));

        if (correspondence) correspondence.clients = selectedClients;
      } else {
        // For single client, use the existing endpoint
        response = await axios.put(
          `${serverUrl}/update-call-client/${id}`,
          { clientId: selectedClients.id },
          { headers: { Authorization: `Bearer ${Cookies.get("jwtToken")}` } }
        );

        // Update state with single client in array format
        setStateClient([selectedClients]);
        setStateCorrespondence(prev => ({ ...prev, clients: [selectedClients] }));

        if (correspondence) correspondence.clients = [selectedClients];
      }

      // Comment out Liam broken code.
      // try {
      //   // Generate insights for THIS SPECIFIC CONTENT
      //   const content = type === 'email' ? 
      //     correspondence?.metadata?.body || correspondence.body :
      //     correspondence?.transcription || correspondence.summary;

      //   const insightsResponse = await axios.post(
      //     `${serverUrl}/api/extract-insights`,
      //     {
      //       content,
      //       contentType: type,
      //       clientId: selectedClient.id,
      //       sourceId: id
      //     },
      //     { headers: { Authorization: `Bearer ${Cookies.get('jwtToken')}` } }
      //   );

      //   // Only update insights if they're available
      //   if (insightsResponse.data.insights_available) {
      //     // Update local state with new insights
      //     setStateCorrespondence(prev => ({
      //       ...prev,
      //       aiInsights: insightsResponse.data
      //     }));

      //     // Generate incremental insights for client
      //     await axios.post(
      //       `${serverUrl}/api/clients/${selectedClient.id}/generate-incremental-insights`,
      //       { contentId: id, contentType: type },
      //       { headers: { Authorization: `Bearer ${Cookies.get('jwtToken')}` } }
      //     );

      //     toast({
      //       title: "Client Updated & Insights Generated",
      //       description: "Client assigned with new conversation and incremental insights",
      //       status: "success",
      //       duration: 3000,
      //       isClosable: true,
      //     });
      //   } else {
      //     toast({
      //       title: "Client Updated",
      //       description: "Client assigned successfully. No actionable insights found in this content.",
      //       status: "info",
      //       duration: 3000,
      //       isClosable: true,
      //     });
      //   }

      //   // Update parent component's insights
      //   if (typeof onInsightsUpdate === 'function') {
      //     onInsightsUpdate(id, insightsResponse.data);
      //   }
      // } catch (error) {
      //   console.error('Error generating insights:', error);
      // }

    } catch (error) {
      console.error("Error tagging client(s):", error);
      toast({
        title: "Update Failed",
        description: error.response?.data?.error || "There was an error updating the client(s).",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleViewTranscript = async() => {
    
    try {
      await axios.post(`${serverUrl}/api/usage/log`, {
        statPath: FeatureStats.TRANSCRIPT_VIEWS,
        value: 1
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      });
      
      if (scrollRef?.current) {
        sessionStorage.setItem('scrollPosition', scrollRef.current.scrollTop.toString());
      }
      navigate(`/transcripts/${transcriptId}`);
    } catch (error) {
      console.error('Error logging transcript view:', error);
    }
  };

  const handleViewEmail = () => {
    if (scrollRef?.current) {
      sessionStorage.setItem('scrollPosition', scrollRef.current.scrollTop.toString());
    }
    navigate(`/correspondence/email/${id}`);
  };

  const handleResync = async () => {
    console.log('GrayBox Component: handleResync - Initiating resync process');
    try {

      // Add tracking before making the resync request
    await axios.post(`${serverUrl}/api/usage/log`, {
      statPath: FeatureStats.CRM_RESYNCS,
      value: 1
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      }
    });

        console.log('GrayBox Component: handleResync - Resyncing all clients for the organization');
        const response = await axios.post(`${serverUrl}/api/clients/resync-all`, {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Cookies.get('jwtToken')}`
            }
        });

        if (response.data.message) {
            toast({
                title: "Resync Initiated",
                description: "All client data resync has been initiated. This may take a few moments.",
                status: "info",
                duration: 5000,
                isClosable: true,
            });
        } else {
            throw new Error(response.data.message || "Failed to initiate resync");
        }
    } catch (error) {
        console.error("Error initiating resync:", error);
        toast({
            title: "Resync Failed",
            description: `Failed to initiate resync: ${error.message}`,
            status: "error",
            duration: 5000,
            isClosable: true,
        });
    } finally {
        console.log('GrayBox Component: handleResync - Resync process completed');
    }
  };

  const handleArchiveCall = async (id) => {
    console.log('Archive Call');
  

    axios.post(`${serverUrl}/api/calls/${id}/archive`, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      },
    }).then(res => {
      console.log(res);
    });
  }

  const handleUnarchiveCall = (id) => {
    console.log('Unarchive Call');
    axios.post(`${serverUrl}/api/calls/${id}/unarchive`, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      },
    }).then(res => {
      console.log(res);
    });
  }

  const handleArchiveEmail = (id) => {
    console.log('Archive Email');
    axios.post(`${serverUrl}/api/emails/${id}/archive`, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      },
    }).then(res => {
      console.log(res);
    });
  }

  const handleUnarchiveEmail = (id) => {
    console.log('Unarchive Email');
    axios.post(`${serverUrl}/api/emails/${id}/unarchive`, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      },
    }).then(res => {
      console.log(res);
    });
  }

  // The original UTC time is stored in the title between || and ||.
  // This function extracts the time and formats it in the user's local timezone, to avoid timezone confusion between the server and client.
  const formatTitleWithDate = (title) => {
    if (!title) return '';
    
    // Check if title contains an ISO timestamp between ||
    const matches = title.match(/\|\|(.*?)\|\|/);
    if (!matches) return title;

    // Extract the parts
    const [fullMatch, isoTime] = matches;
    const baseTitle = title.replace(fullMatch, '').trim();
    
    // Format the date in user's local timezone
    const date = new Date(isoTime);
    const formattedDate = date.toLocaleString(undefined, {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });

    return `${baseTitle} on ${formattedDate}`;
  };

  const handleRetagClient = () => {
    setShowClientSelection(true);
  };

  const handleArchive = async () => {
    try {
      if (type === 'call') {
        if (correspondence?.archived) {
          await handleUnarchiveCall(id);
        } else {
          await handleArchiveCall(id);
        }
      } else if (type === 'email') {
        if (correspondence?.archived) {
          await handleUnarchiveEmail(id);
        } else {
          await handleArchiveEmail(id);
        }
      }

      // Log the correspondence archive
    await axios.post(`${serverUrl}/api/usage/log`, {
      statPath: FeatureStats.CORRESPONDENCE_ARCHIVED,
      value: 1
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      }
    });


      onArchive(id);
      toast({
        title: correspondence?.archived ? "Unarchived" : "Archived",
        description: `Successfully ${correspondence?.archived ? 'unarchived' : 'archived'} the ${type}`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error archiving/unarchiving:', error);
      toast({
        title: "Error",
        description: `Failed to ${correspondence?.archived ? 'unarchive' : 'archive'} the ${type}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleApprove = async (item) => {
    try {
      setLoadingStates(prev => ({ ...prev, [item.id]: true }));
      
      // Get the due date - if not set, default to 7 days from now
      const dueDate = editableDates[item.id] 
        ? new Date(editableDates[item.id]).toISOString()
        : new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString(); // 7 days from now

      // Prepare the action item data
      const actionItemData = {
        assignedTo: assignedUsers[item.id]?.id,
        dueDate: dueDate,
        description: editableDescriptions[item.id] || item.description,
        functionParameters: {
          ...item.functionParameters,
          newValue: {
            ...item.functionParameters?.newValue,
            description: editableDescriptions[item.id] || item.functionParameters?.newValue?.description
          }
        }
      };

      // Log the action item approval
      await axios.post(`${serverUrl}/api/usage/log`, {
        statPath: FeatureStats.ACTION_ITEMS_APPROVED,
        value: 1
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      });

      const response = await axios.post(
        `${serverUrl}/api/actionItems/${item.id}/approve`,
        actionItemData,
        {
          headers: {
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          }
        }
      );

      if (response.status === 200) {

        setStateRows(prevRows => 
          prevRows.map(row => 
            row.id === item.id 
              ? { ...row, aiCompletionStatus: 'SUCCESS' }
              : row
          )
        );

        toast({
          title: "Action Item Approved",
          description: "The action item has been successfully approved.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error approving action item:', error);
      toast({
        title: "Error",
        description: "Failed to approve the action item. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoadingStates(prev => ({ ...prev, [item.id]: false }));
    }
  };

  const handleApproveAll = async () => {
    try {
      setApproveAllLoading(true);
      const pendingItems = stateRows.filter(item => 
        item.aiCompletionStatus === 'PENDING' || item.aiCompletionStatus === 'ERROR'
      );
      
      const newLoadingStates = {};
      pendingItems.forEach(item => {
        newLoadingStates[item.id] = true;
      });
      setLoadingStates(newLoadingStates);

      // Make all API calls in parallel
      await Promise.all(
        pendingItems.map(item => {
          // Get the due date - if not set, default to 7 days from now
          const dueDate = editableDates[item.id] 
            ? new Date(editableDates[item.id]).toISOString()
            : new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString(); // 7 days from now

          return axios.post(
            `${serverUrl}/api/actionItems/${item.id}/approve`,
            {
              assignedTo: assignedUsers[item.id]?.id,
              dueDate: dueDate,
              description: editableDescriptions[item.id] || item.description,
              functionParameters: {
                ...item.functionParameters,
                newValue: {
                  ...item.functionParameters?.newValue,
                  description: editableDescriptions[item.id] || item.functionParameters?.newValue?.description
                }
              }
            },
            {
              headers: {
                'Authorization': `Bearer ${Cookies.get('jwtToken')}`
              }
            }
          );
        })
      );

      // Log multiple approvals at once
      await axios.post(`${serverUrl}/api/usage/log`, {
        statPath: FeatureStats.ACTION_ITEMS_APPROVED,
        value: pendingItems.length
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      });

      // Update all items to SUCCESS status
      setStateRows(prevRows => 
        prevRows.map(row => ({
          ...row,
          aiCompletionStatus: row.aiCompletionStatus === 'PENDING' || row.aiCompletionStatus === 'ERROR' 
            ? 'SUCCESS' 
            : row.aiCompletionStatus
        }))
      );

      toast({
        title: "All Actions Approved",
        description: "All pending action items have been successfully approved.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error approving all action items:', error);
      toast({
        title: "Error",
        description: "Failed to approve all action items. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setApproveAllLoading(false);
      setLoadingStates({});
    }
  };

  const handleReject = async (item) => {
    try {
      setLoadingStates(prev => ({ ...prev, [item.id]: true }));
      const response = await axios.post(
        `${serverUrl}/api/action-items/${item.id}/archive`,
        {},
        {
          headers: {
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          }
        }
      );

      if (response.status === 200) {
        setStateRows(prevRows => prevRows.filter(row => row.id !== item.id));

        toast({
          title: "Action Item Rejected",
          description: "The action item has been successfully rejected.",
          status: "info",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error rejecting action item:', error);
      toast({
        title: "Error",
        description: "Failed to reject the action item. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoadingStates(prev => ({ ...prev, [item.id]: false }));
    }
  };

  const handleAssign = (itemId, user) => {
    setAssignedUsers(prev => ({
      ...prev,
      [itemId]: {
        id: user.id,
        name: user.name
      }
    }));
  };

  const handleDateChange = (itemId, date) => {
    setEditableDates(prev => ({
      ...prev,
      [itemId]: date
    }));
  };

  const handleTextEdit = async (itemId, field, value) => {
    if (field === 'name') {
      setEditableNames(prev => ({
        ...prev,
        [itemId]: value
      }));
    } else if (field === 'description') {
      setEditableDescriptions(prev => ({
        ...prev,
        [itemId]: value
      }));
    }
  };

  const handleSaveActionItem = async (item) => {
    try {
      setIsSaving(prev => ({ ...prev, [item.id]: true }));

      // Prepare the data to update
      const updateData = {
        name: editableNames[item.id] || item.description,
        description: editableDescriptions[item.id] || item.functionParameters?.newValue?.description || '',
        functionParameters: {
          ...item.functionParameters,
          newValue: {
            ...item.functionParameters?.newValue,
            name: editableNames[item.id] || item.description,
            description: editableDescriptions[item.id] || item.functionParameters?.newValue?.description || ''
          }
        }
      };

      // Make the API call to update the action item
      const response = await axios.put(
        `${serverUrl}/api/action-items/${item.id}`,
        updateData,
        {
          headers: {
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          }
        }
      );

      if (response.status === 200) {
        // Update the local state to reflect the changes
        setStateRows(prevRows => 
          prevRows.map(row => 
            row.id === item.id 
              ? { 
                  ...row, 
                  description: updateData.name,
                  functionParameters: {
                    ...row.functionParameters,
                    newValue: {
                      ...row.functionParameters?.newValue,
                      name: updateData.name,
                      description: updateData.description
                    }
                  }
                }
              : row
          )
        );

        // Clear the editing state
        setIsEditing(prev => ({
          ...prev,
          [`${item.id}-name`]: false,
          [`${item.id}-description`]: false
        }));

        toast({
          title: "Action Item Updated",
          description: "The action item has been successfully updated.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        // Log action item edit
        await axios.post(`${serverUrl}/api/usage/log`, {
          statPath: FeatureStats.ACTION_ITEMS_EDITED,
          value: 1
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          }
        });
      }
    } catch (error) {
      console.error('Error updating action item:', error);
      toast({
        title: "Error",
        description: "Failed to update the action item. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSaving(prev => ({ ...prev, [item.id]: false }));
    }
  };

  const handleSendNoteWealthbox = async (summary) => {
    if (summary) {
      await axios.post(`${serverUrl}/api/wealthbox/notes`, {
        content: summary,
        clientId: stateClient[0].id
      }, {
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      });
      toast({
        title: "Note Sent",
        description: "The note has been successfully sent to Wealthbox.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSendNoteRedtail = async (summary) => {
    if (!summary) {
      toast({
        title: "Error",
        description: "No content to send to Redtail",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await axios.post(`${serverUrl}/api/redtail/notes`, {
        content: summary,
        clientIds: stateClient.map((client) => client.id)
      }, {
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      });

      toast({
        title: "Note Sent",
        description: "The note has been successfully sent to Redtail.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error sending note to Redtail:', error);
      
      // Extract error details from the API response
      const errorMessage = error.response?.data?.details || error.response?.data?.error || error.message;
      const errorTitle = error.response?.data?.error || 'Error';
      
      toast({
        title: errorTitle,
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const onEditToggle = async (field, itemId) => {
    const newIsEditing = !isEditing[field];
    setIsEditing(prev => ({
      ...prev,
      [field]: newIsEditing
    }));

    // Only log when finishing an edit
    if (!newIsEditing) {
      if (field.includes('name')) {
        // Log transcription title edit
        await axios.post(`${serverUrl}/api/usage/log`, {
          statPath: FeatureStats.TRANSCRIPTION_TITLES_EDITED,
          value: 1
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          }
        });
      } else if (field.includes('description')) {
        // Log action item edit
        await axios.post(`${serverUrl}/api/usage/log`, {
          statPath: FeatureStats.ACTION_ITEMS_EDITED,
          value: 1
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          }
        });
      }
    }
  };

  return (
    <Box position="relative">
      <VStack 
        spacing={4} 
        align="stretch" 
        p={6}
        bg="white"
        borderRadius="xl"
        boxShadow="sm"
        transition="box-shadow 0.2s ease-in-out"
        _hover={{ boxShadow: 'md' }}
      >
        {/* Header */}
        <HStack spacing={4} align="flex-start">
          <Box 
            p={3}
            bg={getCorrespondenceStyle().bg}
            borderRadius="lg"
            color={getCorrespondenceStyle().color}
          >
            <Icon as={getCorrespondenceStyle().icon} boxSize={6} />
          </Box>

          <VStack align="start" spacing={3} flex={1}>
            {/* Title Section */}
            <HStack w="100%" justify="space-between" align="center">
              {isEditingTitle ? (
                <EditTitleInput 
                  value={newTitle}
                  onChange={setNewTitle}
                  onSave={handleSaveTitle}
                  onCancel={handleCancelEditTitle}
                />
              ) : (
                <TitleDisplay 
                  title={formatTitleWithDate(stateTitle)}
                  date={metadata?.date}
                  isEditable={type === 'call'}
                  onEdit={handleEditTitle}
                  isHovered={isHoveredTitle}
                />
              )}
            </HStack>

            {/* Client Section */}
              <AssignClientButton
                clientName={stateClient}
                onAssignClient={handleClientSelect}
                callId={id}
                buttonSize="sm"
                variant="outline"
                type={type}
              />
          </VStack>
        </HStack>

        {/* Content Section */}
        <Box pl={16}>
          {/* Summary Section */}
          <VStack align="stretch" spacing={4}>
            {stateItems.length > 0 && (
              <Box>
                <Text 
                  fontSize="sm" 
                  fontWeight="semibold" 
                  color="gray.700" 
                  mb={2}
                  letterSpacing="wide"
                >
                  Summary
                </Text>
                <OrderedList spacing={3}>
                  {stateItems.map((item, index) => {
                    // Keep track of accumulated length
                    const previousItemsLength = stateItems
                      .slice(0, index)
                      .reduce((total, item) => total + item.length, 0);
                    
                    // Show item if expanded or within character limit
                    if (isExpandedSummary || previousItemsLength < SUMMARY_CHARACTER_LIMIT) {
                      return (
                        <ListItem 
                          key={index}
                          fontSize="sm"
                          color="gray.700"
                          lineHeight="tall"
                        >
                          {item}
                        </ListItem>
                      );
                    }
                    return null;
                  }).filter(Boolean)}
                </OrderedList>
                {stateItems.some((_, index) => {
                  const previousItemsLength = stateItems
                    .slice(0, index)
                    .reduce((total, item) => total + item.length, 0);
                  return previousItemsLength >= SUMMARY_CHARACTER_LIMIT;
                }) && (
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => setIsExpandedSummary(!isExpandedSummary)}
                    mt={2}
                    color="blue.500"
                    _hover={{ bg: 'blue.50' }}
                    leftIcon={isExpandedSummary ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  >
                    {isExpandedSummary ? 'Show less' : 'Show more'}
                  </Button>
                )}
              </Box>
            )}
            {/* Action Buttons - Moved outside of collapsible section */}
            <Wrap spacing={2} mt={1}>
              <WrapItem>
                <Button
                  size="sm"
                  variant="ghost"
                  leftIcon={type === 'email' ? <FaRegEnvelope /> : <FaPhone />}
                  onClick={type === 'email' ? handleViewEmail : handleViewTranscript}
                  whiteSpace="normal"
                  textAlign="left"
                  height="auto"
                  py={1.5}
                  minW="auto"
                >
                  View {type === 'email' ? 'Email' : 'Transcript'}, Insights, & Follow Up
                </Button>
              </WrapItem>
              
              <WrapItem>
                <Button
                  size="sm"
                  variant="ghost"
                  leftIcon={correspondence?.archived ? <ViewOffIcon /> : <ViewIcon />}
                  onClick={handleArchive}
                  whiteSpace="normal"
                  textAlign="left"
                  height="auto"
                  py={1.5}
                  minW="auto"
                >
                  {correspondence?.archived ? 'Unarchive' : 'Archive'}
                </Button>
              </WrapItem>
              
              {hasWealthboxConnected && (
                <WrapItem>
                  <Button
                    size="sm"
                    variant="ghost"
                    onClick={() => handleSendNoteWealthbox(stateItems.join('\n\n'))}
                    leftIcon={<FaEdit />}
                    whiteSpace="normal"
                    textAlign="left"
                    height="auto"
                    py={1.5}
                    minW="auto"
                  >
                    Send Note to Wealthbox
                  </Button>
                </WrapItem>
              )}
              
              {hasRedtailConnected && (
                <WrapItem>
                  <Button
                    size="sm"
                    variant="ghost"
                    onClick={() => handleSendNoteRedtail(stateItems.join('\n\n'))}
                    leftIcon={<FaEdit />}
                    whiteSpace="normal"
                    textAlign="left"
                    height="auto"
                    py={1.5}
                    minW="auto"
                  >
                    Send Note to Redtail
                  </Button>
                </WrapItem>
              )}
            </Wrap>
            {/* AI Insights Section */}
            <Box>
              {isBetaFeaturesEnabled && aiInsights?.insights?.length > 0 && (
                <>
                  <Button
                    onClick={() => setShowAIInsights(!showAIInsights)}
                    width="100%"
                    variant="outline"
                    size="md"
                    borderRadius="md"
                    borderColor="gray.200"
                    bg="white"
                    _hover={{ bg: 'gray.50' }}
                    rightIcon={
                      <Icon 
                        as={showAIInsights ? ChevronUpIcon : ChevronDownIcon} 
                        transition="transform 0.2s"
                      />
                    }
                    position="relative"
                    height="48px"
                  >
                    <HStack width="100%" justify="space-between">
                      <HStack spacing={3}>
                        <Text 
                          fontSize="sm" 
                          fontWeight="semibold"
                          color="gray.700"
                          letterSpacing="wide"
                        >
                          {showAIInsights ? 'Hide AI Insights' : 'View AI Insights'}
                        </Text>
                        <Text
                          fontSize="sm"
                          color="gray.500"
                          fontWeight="medium"
                        >
                          ({aiInsights.insights.length})
                        </Text>
                      </HStack>
                    </HStack>
                  </Button>

                  <Collapse in={showAIInsights} animateOpacity>
                    <VStack
                      spacing={4}
                      align="stretch"
                      bg="white"
                      borderRadius="md"
                      p={4}
                      borderWidth="1px"
                      borderColor="gray.100"
                      transition="box-shadow 0.2s ease-in-out"
                      boxShadow="sm"
                      _hover={{ boxShadow: 'sm' }}
                      mt={2}
                    >
                      {aiInsights.insights.map((insight, index) => (
                        <Box
                          key={index}
                          borderWidth="1px"
                          borderRadius="md"
                          borderColor="blue.100"
                          bg="blue.50"
                          p={3}
                        >
                          <VStack align="stretch" spacing={3}>
                            {/* Insight Header */}
                            <HStack spacing={3}>
                              <Icon as={FaInfoCircle} color="blue.500" />
                              <Text color="gray.700" fontWeight="medium">
                                {formatCategoryName(insight.category)} | {insight.subcategory}
                              </Text>
                            </HStack>

                            {/* Insight Content */}
                            <Text color="gray.600" pl={7}>
                              {insight.insight}
                            </Text>

                            {/* Action Items Section */}
                            {insight.actionItems && insight.actionItems.length > 0 && (
                              <Box
                                pl={7}
                                mt={2}
                                p={3}
                                bg="white"
                                borderRadius="md"
                                borderWidth="1px"
                                borderColor="gray.200"
                              >
                                <VStack align="stretch" spacing={3}>
                                  <HStack justify="space-between">
                                    <Text color="gray.700" fontWeight="medium">
                                      Suggested Actions
                                    </Text>
                                    <Text fontSize="sm" color="gray.500">
                                      {insight.actionItems.length} item{insight.actionItems.length !== 1 ? 's' : ''}
                                    </Text>
                                  </HStack>

                                  {insight.actionItems.map((item, idx) => {
                                    const actionItem = typeof item === 'string' ? JSON.parse(item) : item;
                                    return (
                                      <Box
                                        key={idx}
                                        p={3}
                                        bg="gray.50"
                                        borderRadius="md"
                                        borderWidth="1px"
                                        borderColor="gray.200"
                                      >
                                        <VStack align="stretch" spacing={3}>
                                          {/* Title and Actions Row */}
                                          <HStack justify="space-between" align="flex-start">
                                            <VStack align="start" spacing={1} flex={1}>
                                              <Text 
                                                fontSize="sm"
                                                fontWeight="medium"
                                                color="gray.700"
                                              >
                                                {actionItem.description || actionItem.functionParameters?.newValue?.name}
                                              </Text>
                                              
                                              {(actionItem.functionParameters?.newValue?.description || actionItem.rationale) && (
                                                <Text fontSize="xs" color="gray.600">
                                                  {actionItem.functionParameters?.newValue?.description || actionItem.rationale}
                                                </Text>
                                              )}
                                            </VStack>
                                            <HStack spacing={2}>
                                              <Button
                                                size="sm"
                                                colorScheme="green"
                                                variant="ghost"
                                                leftIcon={<CheckIcon />}
                                                onClick={() => handleApprove(actionItem)}
                                                isDisabled={!stateClient?.length || !assignedUsers[actionItem.id]}
                                              >
                                                Approve
                                              </Button>
                                              <IconButton
                                                size="sm"
                                                icon={<CloseIcon />}
                                                colorScheme="red"
                                                variant="ghost"
                                                onClick={() => handleReject(actionItem)}
                                                isDisabled={!stateClient?.length}
                                              />
                                            </HStack>
                                          </HStack>

                                          {/* Assignment and Edit Row */}
                                          <HStack spacing={4} align="center">
                                            {crm && (
                                              <>
                                                <Menu>
                                                  <MenuButton
                                                    as={Button}
                                                    size="sm"
                                                    variant="outline"
                                                    rightIcon={<ChevronDownIcon />}
                                                    colorScheme="blue"
                                                  >
                                                    {assignedUsers[item.id]?.name ? assignedUsers[item.id].name : 'Assign To'}
                                                  </MenuButton>
                                                  <MenuList>
                                                    {userCrms.map((crm) => (
                                                      <MenuItem
                                                        key={crm.id}
                                                        onClick={() => handleAssign(item.id, crm)}
                                                      >
                                                        {crm.name}
                                                      </MenuItem>
                                                    ))}
                                                  </MenuList>
                                                </Menu>

                                                <Input
                                                  type="date"
                                                  size="sm"
                                                  value={editableDates[item.id] || ''}
                                                  onChange={(e) => handleDateChange(item.id, e.target.value)}
                                                  min={new Date().toISOString().split('T')[0]}
                                                  placeholder="Due date"
                                                  w="auto"
                                                  borderColor="gray.300"
                                                  _hover={{ borderColor: "gray.400" }}
                                                  _focus={{ borderColor: "blue.500", boxShadow: "0 0 0 1px blue.500" }}
                                                />
                                              </>
                                            )}
                                            <IconButton
                                              size="sm"
                                              icon={<EditIcon />}
                                              variant="ghost"
                                              onClick={() => {
                                                onEditToggle(`${item.id}-name`);
                                                onEditToggle(`${item.id}-description`);
                                              }}
                                              aria-label="Edit details"
                                            />
                                          </HStack>
                                        </VStack>
                                      </Box>
                                    );
                                  })}
                                </VStack>
                              </Box>
                            )}
                          </VStack>
                        </Box>
                      ))}
                    </VStack>
                  </Collapse>
                </>
              )}
            </Box>

            {/* Action Items Preview */}
            {stateRows.length > 0 && (
              <Box>
                <Button
                  onClick={onToggle}
                  width="100%"
                  variant="outline"
                  size="md"
                  borderRadius="md"
                  borderColor="gray.200"
                  bg="white"
                  _hover={{ bg: 'gray.50' }}
                  rightIcon={
                    <Icon 
                      as={isOpen ? ChevronUpIcon : ChevronDownIcon} 
                      transition="transform 0.2s"
                    />
                  }
                  position="relative"
                  height="48px"
                >
                  <HStack width="100%" justify="space-between">
                    <HStack spacing={3}>
                      <Text 
                        fontSize="sm" 
                        fontWeight="semibold"
                        color="gray.700"
                        letterSpacing="wide"
                      >
                        {isOpen ? 'Hide' : 'View'} Action Items
                      </Text>
                      <Text
                        fontSize="sm"
                        color="gray.500"
                        fontWeight="medium"
                      >
                        ({stateRows.filter(item => !item.archived).length})
                      </Text>
                    </HStack>
                    
                    {stateRows.every(item => item.aiCompletionStatus === 'SUCCESS') ? (
                      <HStack>
                        <Icon 
                          as={CheckIcon} 
                          color="green.500" 
                          boxSize={3}
                        />
                        <Text 
                          fontSize="sm" 
                          color="green.500"
                          fontWeight="medium"
                        >
                          All completed
                        </Text>
                      </HStack>
                    ) : (
                      <Text 
                        fontSize="sm" 
                        color={stateRows.some(item => item.aiCompletionStatus === 'PENDING' || item.aiCompletionStatus === 'ERROR' && !item.archived) ? 'red.500' : 'green.500'} 
                        fontWeight="medium"
                      >
                        {stateRows.filter(item => item.aiCompletionStatus === 'PENDING' || item.aiCompletionStatus === 'ERROR' && !item.archived).length} pending
                      </Text>
                    )}
                  </HStack>
                </Button>

                <Collapse in={isOpen} animateOpacity>
                  <VStack 
                    spacing={2} 
                    align="stretch"
                    bg="white"
                    borderRadius="md"
                    p={4}
                    borderWidth="1px"
                    borderColor="gray.100"
                    transition="box-shadow 0.2s ease-in-out"
                    boxShadow="sm"
                    _hover={{ boxShadow: 'sm' }}
                  >
                    {stateRows.filter(item => !item.archived).map((item, index) => (
                      <Box 
                        key={index}
                        borderWidth="1px"
                        borderRadius="md"
                        borderColor={item.aiCompletionStatus === 'PENDING' ? 'blue.100' : 'gray.100'}
                        bg={item.aiCompletionStatus === 'PENDING' ? 'gray.50' : 'white'}
                        p={3}
                      >
                        <VStack align="stretch" spacing={3}>
                          {/* Title and Actions Row */}
                          <HStack justify="space-between" align="flex-start">
                            <VStack align="start" spacing={1} flex={1}>
                              {isEditing[`${item.id}-name`] ? (
                                <Input
                                  size="sm"
                                  value={editableNames[item.id] || item.description}
                                  onChange={(e) => handleTextEdit(item.id, 'name', e.target.value)}
                                  onBlur={() => {}}
                                  autoFocus
                                />
                              ) : (
                                <HStack alignItems="center" spacing={2}>
                                  <Text 
                                    fontSize="sm"
                                    fontWeight="medium"
                                    color="gray.700"
                                  >
                                    {item.description}
                                  </Text>
                                  {item.aiCompletionStatus === 'SUCCESS' && (
                                    <Badge colorScheme="green" variant="solid" fontSize="2xs" px={2} py={0.5}>
                                      Sent to CRM
                                    </Badge>
                                  )}
                                </HStack>
                              )}
                              
                              {isEditing[`${item.id}-description`] ? (
                                <Input
                                  size="sm"
                                  value={editableDescriptions[item.id] || item.functionParameters?.newValue?.description || ''}
                                  onChange={(e) => handleTextEdit(item.id, 'description', e.target.value)}
                                  onBlur={() => {}}
                                  placeholder="Add description..."
                                  autoFocus
                                />
                              ) : (
                                item.functionParameters?.newValue?.description && (
                                  <Text fontSize="xs" color="gray.600">
                                    {item.functionParameters.newValue.description}
                                  </Text>
                                )
                              )}
                            </VStack>
                            <HStack spacing={2}>
                              {(item.aiCompletionStatus === 'PENDING' || item.aiCompletionStatus === 'ERROR') && (
                                <>
                                  <Tooltip
                                    label={
                                      !stateClient?.length 
                                        ? "Please assign a client first"
                                        : !assignedUsers[item.id] && crm
                                          ? "Please assign this action to someone"
                                          : "Approve this action"
                                    }
                                    hasArrow
                                  >
                                    <Button
                                      size="sm"
                                      colorScheme="green"
                                      variant="ghost"
                                      leftIcon={<CheckIcon />}
                                      onClick={() => handleApprove(item)}
                                      isDisabled={(!stateClient?.length || !assignedUsers[item.id] || loadingStates[item.id]) && crm}
                                      isLoading={loadingStates[item.id]}
                                    >
                                      Create in CRM
                                    </Button>
                                  </Tooltip>
                                  <IconButton
                                    size="sm"
                                    icon={<CloseIcon />}
                                    colorScheme="red"
                                    variant="ghost"
                                    onClick={() => handleReject(item)}
                                    isDisabled={!stateClient?.length || loadingStates[item.id]}
                                    isLoading={loadingStates[item.id]}
                                  />
                                </>
                              )}
                            </HStack>
                          </HStack>

                          {/* Assignment and Due Date Row */}
                          {((item.aiCompletionStatus === 'PENDING' || item.aiCompletionStatus === 'ERROR') && crm) && (
                            <HStack spacing={4} align="center">
                              <Menu>
                                <MenuButton
                                  as={Button}
                                  size="sm"
                                  variant="outline"
                                  rightIcon={<ChevronDownIcon />}
                                  colorScheme="blue"
                                >
                                  {assignedUsers[item.id]?.name ? assignedUsers[item.id].name : 'Assign To'}
                                </MenuButton>
                                <MenuList>
                                  {userCrms.map((crm) => (
                                    <MenuItem 
                                      key={crm.id}
                                      onClick={() => handleAssign(item.id, crm)}
                                    >
                                      {crm.name}
                                    </MenuItem>
                                  ))}
                                </MenuList>
                              </Menu>
                              
                              <Input
                                type="date"
                                size="sm"
                                value={editableDates[item.id] || ''}
                                onChange={(e) => handleDateChange(item.id, e.target.value)}
                                min={new Date().toISOString().split('T')[0]}
                                placeholder="Due date"
                                w="auto"
                                borderColor="gray.300"
                                _hover={{ borderColor: "gray.400" }}
                                _focus={{ borderColor: "blue.500", boxShadow: "0 0 0 1px blue.500" }}
                              />
                              
                              <IconButton
                                size="sm"
                                icon={<EditIcon />}
                                variant="ghost"
                                onClick={() => {
                                  onEditToggle(`${item.id}-name`);
                                  onEditToggle(`${item.id}-description`);
                                }}
                                aria-label="Edit details"
                              />
                              
                              {/* Add Save Button */}
                              {(isEditing[`${item.id}-name`] || isEditing[`${item.id}-description`]) && (
                                <IconButton
                                  size="sm"
                                  icon={<Icon as={FaSave} />}
                                  variant="ghost"
                                  colorScheme="green"
                                  onClick={() => handleSaveActionItem(item)}
                                  isLoading={isSaving[item.id]}
                                  aria-label="Save changes"
                                />
                              )}
                            </HStack>
                          )}
                        </VStack>
                      </Box>
                    ))}

                    {/* Footer Actions - Remove duplicate buttons */}
                    <HStack justify="flex-end" mt={2}>
                      {stateRows.some(item => item.aiCompletionStatus === 'PENDING' || item.aiCompletionStatus === 'ERROR') && (
                        <Tooltip
                          label={
                            !stateClient?.length 
                              ? "Please assign a client first"
                              : !canApproveAll
                                ? "Please assign all pending actions to someone"
                                : "Approve all pending actions"
                          }
                          hasArrow
                        >
                          <Button
                            size="sm"
                            colorScheme="blue"
                            variant="solid"
                            rightIcon={<CheckIcon />}
                            onClick={handleApproveAll}
                            isDisabled={!stateClient?.length || !canApproveAll || approveAllLoading}
                            isLoading={approveAllLoading}
                          >
                            Approve All
                          </Button>
                        </Tooltip>
                      )}
                    </HStack>
                  </VStack>
                </Collapse>
              </Box>
            )}
          </VStack>
        </Box>
      </VStack>
    </Box>
  );
};

GrayBox.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  metadata: PropTypes.shape({
    subject: PropTypes.string,
    date: PropTypes.string,
    clientNames: PropTypes.string,
  }),
  listItems: PropTypes.arrayOf(PropTypes.string),
  rows: PropTypes.array,
  transcriptId: PropTypes.string.isRequired,
  client: PropTypes.array,
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['call', 'email']).isRequired,
  scrollRef: PropTypes.shape({
    current: PropTypes.any
  }),
  onArchive: PropTypes.func.isRequired,
  errorItems: PropTypes.array,
  correspondence: PropTypes.object,
  nonClientSpeakers: PropTypes.array,
  onInsightsUpdate: PropTypes.func,
};

export default GrayBox;