import { useState, useEffect, useRef } from 'react';
import { BufferedAudioPlayer, getTranscriptionDuration } from '../utils/audioUtils';

/**
 * Custom hook for managing audio playback with buffering
 * @param {Object} options - Configuration options
 * @param {string} options.audioUrl - URL of the audio file
 * @param {Array} options.transcription - Transcription data
 * @param {HTMLAudioElement} options.audioElement - Audio element to use
 * @param {number} options.bufferAhead - Seconds to buffer ahead (default: 30)
 * @param {number} options.chunkSize - Size of each chunk in bytes (default: 1MB)
 * @returns {Object} Audio player state and controls
 */
const useAudioPlayer = (options = {}) => {
  const {
    audioUrl,
    transcription,
    audioElement,
    bufferAhead = 30,
    chunkSize = 1024 * 1024
  } = options;
  
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isBuffering, setIsBuffering] = useState(false);
  const [error, setError] = useState(null);
  
  const audioRef = useRef(audioElement);
  const playerRef = useRef(null);
  const transcriptionDuration = useRef(getTranscriptionDuration(transcription));
  
  // Update audioRef if audioElement changes
  useEffect(() => {
    if (audioElement) {
      audioRef.current = audioElement;
    }
  }, [audioElement]);
  
  // Initialize the audio player
  useEffect(() => {
    if (!audioUrl || !audioRef.current) return;
    
    // Set up event listeners for the audio element
    const handleTimeUpdate = () => {
      if (!audioRef.current) return;
      
      const time = audioRef.current.currentTime;
      setCurrentTime(time);
      
      const audioDuration = audioRef.current.duration;
      const effectiveDuration = isFinite(audioDuration) && audioDuration > 0 
        ? audioDuration 
        : transcriptionDuration.current;
      
      if (effectiveDuration > 0) {
        setProgress((time / effectiveDuration) * 100);
        setDuration(effectiveDuration);
      }
    };
    
    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);
    const handleEnded = () => setIsPlaying(false);
    const handleLoadedMetadata = () => {
      if (audioRef.current && isFinite(audioRef.current.duration)) {
        setDuration(audioRef.current.duration);
      }
    };
    
    // Add event listeners
    audioRef.current.addEventListener('timeupdate', handleTimeUpdate);
    audioRef.current.addEventListener('play', handlePlay);
    audioRef.current.addEventListener('pause', handlePause);
    audioRef.current.addEventListener('ended', handleEnded);
    audioRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);
    
    // Set the audio source if not already set
    if (!audioRef.current.src || !audioRef.current.src.includes(audioUrl)) {
      audioRef.current.src = audioUrl;
      audioRef.current.load();
    }
    
    // Clean up event listeners
    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
        audioRef.current.removeEventListener('play', handlePlay);
        audioRef.current.removeEventListener('pause', handlePause);
        audioRef.current.removeEventListener('ended', handleEnded);
        audioRef.current.removeEventListener('loadedmetadata', handleLoadedMetadata);
      }
    };
  }, [audioUrl, audioRef.current]);
  
  // Update transcription duration when transcription changes
  useEffect(() => {
    transcriptionDuration.current = getTranscriptionDuration(transcription);
  }, [transcription]);
  
  // Play/pause toggle
  const handlePlayPause = () => {
    if (!audioRef.current) return;
    
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
  };
  
  // Skip forward/backward
  const handleSkip = (seconds) => {
    if (!audioRef.current) return;
    
    const newTime = currentTime + seconds;
    audioRef.current.currentTime = newTime;
  };
  
  // Jump to specific timestamp
  const jumpToTimestamp = (timestamp) => {
    if (!audioRef.current) return;
    
    audioRef.current.currentTime = timestamp;
    if (!isPlaying) {
      audioRef.current.play();
    }
  };
  
  // Handle seek (percentage)
  const handleSeek = (percent) => {
    if (!audioRef.current) return;
    
    const effectiveDuration = isFinite(duration) && duration > 0 
      ? duration 
      : transcriptionDuration.current;
    
    const seekTime = (percent / 100) * effectiveDuration;
    audioRef.current.currentTime = seekTime;
  };
  
  return {
    isPlaying,
    currentTime,
    duration,
    progress,
    isBuffering,
    error,
    handlePlayPause,
    handleSkip,
    jumpToTimestamp,
    handleSeek
  };
};

export default useAudioPlayer; 