import { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Heading,
  Text,
  VStack,
  List,
  ListItem,
  ListIcon,
  useColorModeValue,
  ButtonGroup,
  Flex,
  Checkbox,
  Link,
  useToast,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';
import { useAuth } from '../../../AuthContext';
import axios from 'axios';

const CheckoutSimple = () => {
  const { logout } = useAuth();
  const [billingCycle, setBillingCycle] = useState('monthly');
  const [tosAccepted, setTosAccepted] = useState(false);
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const toast = useToast();
  const serverUrl = process.env.REACT_APP_API_URL;

  const handleCheckout = async () => {
    if (!tosAccepted) {
      toast({
        title: "Terms of Service Required",
        description: "You must accept the Terms of Service to continue.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    
    try {
      // Submit ToS acceptance to the server
      await axios.post(`${serverUrl}/api/user/accept-tos`);
      
      // Continue with checkout process
      const queryParams = new URLSearchParams({
        plan: 'standard',
        time: billingCycle
      }).toString();
      
      window.location.href = `/checkoutNext?${queryParams}`;
    } catch (error) {
      console.error('Error during checkout:', error);
      toast({
        title: "Checkout Error",
        description: "There was an error processing your request. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const plan = {
    name: 'Standard',
    price: billingCycle === 'monthly' ? 145 : 119,
    description: "Perfect for individual advisors looking to streamline their client meetings and communications.",
    features: [
      'AI Meeting Assistant',
      'CRM Integration',
      'Email & Calendar Sync',
      'Meeting Transcripts & Summaries',
      'Client Insights Dashboard',
      'Multi-user Access'
    ],
    gradient: 'linear-gradient(135deg, #00417D 0%, #2B6CB0 100%)'
  };

  return (
    <Box
      bgGradient="linear-gradient(180deg, rgba(49, 130, 206, 0.05) 0%, rgba(0, 65, 125, 0.05) 100%)"
      minH="100vh"
      position="relative"
    >
      <Flex position="absolute" top={4} right={4} zIndex={2}>
        <Button
          onClick={logout}
          size="sm"
          variant="ghost"
          color="#00417D"
          _hover={{
            bg: 'blue.50',
          }}
        >
          Logout
        </Button>
      </Flex>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Container maxW="container.md" py={10}>
          <VStack spacing={8} align="center" mb={10}>
            <Heading size="2xl" color="#00417D">Get Started with DataDasher</Heading>
            <Text fontSize="lg" color="gray.600" textAlign="center">
              Streamline your client meetings and communications with our AI-powered platform.
            </Text>
            
            <ButtonGroup size="lg" isAttached variant="outline">
              <Button
                bg={billingCycle === 'monthly' ? '#3182CE' : 'white'}
                color={billingCycle === 'monthly' ? 'white' : 'gray.500'}
                onClick={() => setBillingCycle('monthly')}
                _hover={{
                  bg: billingCycle === 'monthly' ? '#2B6CB0' : 'gray.50'
                }}
              >
                Monthly
              </Button>
              <Button
                bg={billingCycle === 'yearly' ? '#3182CE' : 'white'}
                color={billingCycle === 'yearly' ? 'white' : 'gray.500'}
                onClick={() => setBillingCycle('yearly')}
                _hover={{
                  bg: billingCycle === 'yearly' ? '#2B6CB0' : 'gray.50'
                }}
              >
                Yearly (save 10%)
              </Button>
            </ButtonGroup>
          </VStack>

          <Box
            bg={bgColor}
            p={8}
            borderRadius="xl"
            border="1px"
            borderColor={borderColor}
            boxShadow="lg"
            position="relative"
            overflow="hidden"
            maxW="600px"
            mx="auto"
            _before={{
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              height: '4px',
              bgGradient: plan.gradient
            }}
            _hover={{
              transform: 'translateY(-4px)',
              transition: 'transform 0.2s',
              boxShadow: 'xl'
            }}
          >
            <VStack align="stretch" spacing={6}>
              <Box textAlign="left" mb={0}>
                <Text
                  fontSize="2xl"
                  fontWeight="bold"
                  color="#00417D"
                  letterSpacing="wide"
                  textTransform="uppercase"
                  bgGradient="linear-gradient(135deg, #00417D 0%, #2B6CB0 100%)"
                  bgClip="text"
                  mb={0}
                >
                  Advisor Assistant AI
                </Text>
              </Box>
              <Box>
                <Text
                  display="inline-block"
                  px={6}
                  py={2}
                  color="#3182CE"
                  bg="blue.50"
                  fontSize="md"
                  fontWeight="bold"
                  rounded="full"
                  mb={2}
                  borderWidth="1px"
                  borderColor="blue.200"
                  boxShadow="sm"
                  _hover={{
                    transform: 'translateY(-1px)',
                    boxShadow: 'md',
                  }}
                  transition="all 0.2s"
                  textTransform="uppercase"
                  letterSpacing="wide"
                >
                  {plan.name}
                </Text>
                <Text fontSize="4xl" fontWeight="bold" mt={4}>
                  ${plan.price}
                  <Text as="span" fontSize="lg" fontWeight="normal" color="gray.500">
                    /month
                  </Text>
                </Text>
                <Text color="gray.500" mt={2}>
                  {plan.description}
                </Text>
              </Box>

              <List spacing={3} flex="1">
                {plan.features.map((feature) => (
                  <ListItem key={feature}>
                    <ListIcon as={CheckIcon} color="#3182CE" />
                    {feature}
                  </ListItem>
                ))}
              </List>

              <Button
                size="lg"
                bg="#3182CE"
                color="white"
                width="full"
                onClick={handleCheckout}
                _hover={{
                  bg: '#2B6CB0',
                  transform: 'translateY(-2px)',
                  boxShadow: 'md'
                }}
                isDisabled={!tosAccepted}
              >
                Get started
              </Button>
              
              <Checkbox 
                isChecked={tosAccepted} 
                onChange={(e) => setTosAccepted(e.target.checked)}
                colorScheme="blue"
                size="md"
                mt={2}
              >
                I accept the <Link href="https://www.datadasher.ai/terms/" color="blue.500" isExternal>Terms of Service</Link> and <Link href="https://www.datadasher.ai/policy/" color="blue.500" isExternal>Privacy Policy</Link>
              </Checkbox>
              
              <Text fontSize="sm" color="gray.500" textAlign="center">
                Need a custom plan? <Button variant="link" color="#3182CE" onClick={() => window.location.href = '/checkout'}>View all options</Button>
              </Text>
            </VStack>
          </Box>
        </Container>
      </motion.div>
    </Box>
  );
};

export default CheckoutSimple; 
