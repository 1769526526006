import React, { useState, useEffect } from 'react';
import {
  Box, VStack, HStack, Text, Heading, Button,
  Input, Textarea, IconButton, Divider,
  UnorderedList, ListItem
} from '@chakra-ui/react';
import { FaEdit, FaSave, FaTimes, FaEnvelope } from 'react-icons/fa';
import { EditIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { formatTitleWithDate } from '../../../../utils/transcriptionUtils';

/**
 * Component for displaying and editing call summary
 */
const SummaryPanel = ({
  data,
  title,
  summary,
  type,
  isEditingSummary,
  handleEditSummary,
  handleCancelEditSummary,
  handleSaveSummary,
}) => {
  const [newTitle, setNewTitle] = useState(title || '');
  const [newSummary, setNewSummary] = useState(summary || '');
  const [speakerStats, setSpeakerStats] = useState({});
  const [transcriptionDuration, setTranscriptionDuration] = useState(0);

  // Update state when props change
  useEffect(() => {
    setNewTitle(title || '');
    setNewSummary(summary || '');

    // Calculate speaker statistics if call data is available
    if (data?.transcription) {
      const stats = calculateSpeakerStats(data.transcription);
      setSpeakerStats(stats);
    }
  }, [title, summary, data]);

  // Calculate speaker statistics from transcript
  const calculateSpeakerStats = (transcription) => {
    console.log('transcription', transcription);
    if (!transcription || !transcription.length) return {};

    try {
      const speakerStats = {};
      let overallTotalDuration = 0;

      // First pass: calculate total duration
      transcription.forEach(segment => {
        if (!segment.speaker) return;

        const duration = segment.end_time - segment.start_time;
        overallTotalDuration += duration;
      });

      // Second pass: calculate stats per speaker
      transcription.forEach(segment => {
        if (!segment.speaker || !segment.text) return;

        const speaker = segment.speaker;
        const duration = segment.end_time - segment.start_time;
        const words = segment.text.split(/\s+/).filter(Boolean).length;

        if (!speakerStats[speaker]) {
          speakerStats[speaker] = {
            totalDuration: 0,
            totalWords: 0,
            percentageTime: 0,
            wpm: 0
          };
        }

        speakerStats[speaker].totalDuration += duration;
        speakerStats[speaker].totalWords += words;
      });

      // Calculate percentages and WPM
      for (const speaker in speakerStats) {
        const totalDuration = speakerStats[speaker].totalDuration;
        const totalWords = speakerStats[speaker].totalWords;

        speakerStats[speaker].percentageTime = overallTotalDuration > 0 ? Math.round((totalDuration / overallTotalDuration) * 100) : 0;
        speakerStats[speaker].wpm = totalDuration > 0 ? Math.round((totalWords / totalDuration) * 60) : 0;
      }
    } catch (error) {
      console.error('Error calculating speaker stats:', error);
      return {};
    }
    console.log('speakerStats', speakerStats);
    console.log('transcriptionDuration', transcriptionDuration);
    return speakerStats;
  };

  // Format time (seconds) to MM:SS format
  const formatTime = (seconds) => {
    if (!seconds) return '00:00';
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  // Extract timestamp from title or audio URL
  const extractTimestampFromTitle = (title, audioUrl) => {
    if (!title && !audioUrl) return null;

    // Try to extract date from title
    if (title) {
      const dateMatch = title.match(/(\d{1,2})[\/\-](\d{1,2})[\/\-](\d{2,4})/);
      if (dateMatch) {
        const [_, month, day, year] = dateMatch;
        return new Date(`${month}/${day}/${year.length === 2 ? '20' + year : year}`);
      }
    }

    // Try to extract from audio URL as fallback
    if (audioUrl) {
      const dateMatch = audioUrl.match(/(\d{4})-(\d{2})-(\d{2})/);
      if (dateMatch) {
        const [_, year, month, day] = dateMatch;
        return new Date(`${month}/${day}/${year}`);
      }
    }

    return null;
  };

  // Parse timestamp string (MM:SS) to seconds
  const parseTimestamp = (timestamp) => {
    if (!timestamp) return 0;

    const parts = timestamp.split(':');
    if (parts.length === 2) {
      const minutes = parseInt(parts[0], 10);
      const seconds = parseInt(parts[1], 10);
      return minutes * 60 + seconds;
    }

    return 0;
  };

  // Jump to specific timestamp in audio player
  const jumpToTimestamp = (seconds) => {
    // This would typically interact with an audio player component
    console.log(`Jumping to timestamp: ${seconds} seconds`);
    // Implementation would depend on how audio playback is handled
  };

  // Handle title input change
  const handleTitleChange = (e) => {
    setNewTitle(e.target.value);
  };

  // Handle summary input change
  const handleSummaryChange = (e) => {
    setNewSummary(e.target.value);
  };

  // Format the summary as a list
  const summaryItems = summary
    ? summary.split('\n').filter(item => item.trim() !== '')
    : [];

  return (
    <VStack spacing={4} align="stretch" overflowY="auto" height="100%" p={2}>
      {/* Summary Section */}
      <Box bg="gray.50" p={6} borderRadius="lg">
        <HStack justify="space-between" mb={4}>
          <Heading size="md">Summary</Heading>
          {isEditingSummary ? (
            <HStack>
              <IconButton
                icon={<CheckIcon />}
                colorScheme="green"
                size="sm"
                onClick={() => handleSaveSummary(newSummary)}
                aria-label="Save summary"
              />
              <IconButton
                icon={<CloseIcon />}
                colorScheme="red"
                size="sm"
                onClick={handleCancelEditSummary}
                aria-label="Cancel edit"
              />
            </HStack>
          ) : (
            <IconButton
              icon={<EditIcon />}
              size="sm"
              onClick={handleEditSummary}
              aria-label="Edit summary"
            />
          )}
        </HStack>
        {isEditingSummary ? (
          <Textarea
            value={newSummary}
            onChange={handleSummaryChange}
            minHeight="200px"
            placeholder="Enter summary..."
          />
        ) : (
          <Text whiteSpace="pre-wrap">
            {summary || 'No summary available'}
          </Text>
        )}
      </Box>

      {/* Topics Section */}
      {data?.topics?.topics?.length > 0 && (
        <Box bg="white" p={6} borderRadius="lg" borderWidth="1px" borderColor="gray.200">
          <Heading size="md" mb={4}>Topics</Heading>
          <VStack align="stretch" spacing={3}>
            {data?.topics?.topics?.map((topic, index) => {
              const timestampSeconds = parseTimestamp(topic.timestamp);
              return (
                <HStack
                  key={index}
                  p={3}
                  bg="gray.50"
                  borderRadius="md"
                  spacing={3}
                  _hover={{ bg: "gray.100" }}
                >
                  <Button
                    variant="link"
                    colorScheme="blue"
                    fontWeight="medium"
                    onClick={() => jumpToTimestamp(timestampSeconds)}
                  >
                    {topic.timestamp}
                  </Button>
                  <Text>{topic.topic}</Text>
                </HStack>
              );
            })}
          </VStack>
        </Box>
      )}

      {/* Call Details Section */}
      {/* Commented out Speaker Stats 3/13/2025, not working yet */}
      {/* {type !== 'email' && (
        <Box bg="gray.50" p={6} borderRadius="lg">
          <Heading size="md" mb={4}>Speaker Statistics</Heading>
          <VStack align="stretch" spacing={3}>
            <HStack>
              <Text fontWeight="medium" color="gray.600" minWidth="120px">Duration:</Text>
              <Text>{formatTime(transcriptionDuration)}</Text>
            </HStack>
            <HStack>
              <Text fontWeight="medium" color="gray.600" minWidth="120px">Date:</Text>
              <Text>
                {extractTimestampFromTitle(title, data?.audioUrl)?.toLocaleDateString() || 'Not available'}
              </Text>
            </HStack>
            {Object.entries(speakerStats).map(([speaker, stats]) => (
              <HStack key={speaker}>
                <Text fontWeight="medium" color="gray.600" minWidth="120px">{speaker}:</Text>
                <Text>{stats.percentageTime}% of time, {stats.wpm} WPM</Text>
              </HStack>
            ))}
          </VStack>
        </Box>
      )} */}
    </VStack>
  );
};

export default SummaryPanel; 