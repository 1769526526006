import React from 'react';
import {
  Box,
  Flex,
  IconButton,
  Progress,
  Text,
  Tooltip,
  Spinner,
  HStack
} from '@chakra-ui/react';
import { 
  FaPlay, 
  FaPause, 
  FaStepBackward, 
  FaStepForward,
  FaVolumeUp,
  FaVolumeMute
} from 'react-icons/fa';
import { ChevronLeftIcon, ChevronRightIcon, RepeatIcon } from '@chakra-ui/icons';
import { formatTime } from '../utils/audioUtils';
import { AudioPlayerSettings } from '../constants';

/**
 * Audio player component with buffering support
 */
const AudioPlayer = ({
  isPlaying,
  currentTime,
  duration,
  progress,
  isBuffering,
  onPlayPause,
  onSkip,
  onSeek,
  skipForwardSeconds = AudioPlayerSettings.SKIP_FORWARD_SECONDS,
  skipBackwardSeconds = AudioPlayerSettings.SKIP_BACKWARD_SECONDS
}) => {
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [tooltipValue, setTooltipValue] = React.useState(0);
  const [isMuted, setIsMuted] = React.useState(false);
  const [volume, setVolume] = React.useState(100);
  const [prevVolume, setPrevVolume] = React.useState(100);
  
  // Handle volume change
  const handleVolumeChange = (newVolume) => {
    setVolume(newVolume);
    setIsMuted(newVolume === 0);
  };
  
  // Toggle mute
  const toggleMute = () => {
    if (isMuted) {
      setVolume(prevVolume);
      setIsMuted(false);
    } else {
      setPrevVolume(volume);
      setVolume(0);
      setIsMuted(true);
    }
  };
  
  // Handle seek click for the Progress component
  const handleSeekClick = (e) => {
    const progressBar = e.currentTarget;
    const rect = progressBar.getBoundingClientRect();
    const clickPosition = e.clientX - rect.left;
    const percentClicked = (clickPosition / rect.width) * 100;
    onSeek(percentClicked);
  };
  
  // Handle restart
  const handleRestart = () => {
    onSeek(0);
  };
  
  return (
    <Box
      position="fixed"
      bottom={0}
      left={0}
      right={0}
      bg="gray.100"
      color="blue.500"
      p={4}
      boxShadow="0 -2px 10px rgba(0, 0, 0, 0.1)"
      zIndex={999}
    >
      <Flex align="center" justify="space-between">
        <HStack spacing={4}>
          <IconButton
            icon={isBuffering ? <Spinner size="sm" /> : isPlaying ? <FaPause /> : <FaPlay />}
            onClick={onPlayPause}
            aria-label={isPlaying ? "Pause" : "Play"}
            colorScheme="blue"
            variant="ghost"
            size="lg"
            isDisabled={isBuffering}
          />
          <IconButton
            icon={<ChevronLeftIcon />}
            onClick={() => onSkip(-skipBackwardSeconds)}
            aria-label={`Rewind ${skipBackwardSeconds} seconds`}
            variant="ghost"
            isDisabled={isBuffering}
          />
          <IconButton
            icon={<ChevronRightIcon />}
            onClick={() => onSkip(skipForwardSeconds)}
            aria-label={`Forward ${skipForwardSeconds} seconds`}
            variant="ghost"
            isDisabled={isBuffering}
          />
          <IconButton
            icon={<RepeatIcon />}
            onClick={handleRestart}
            aria-label="Restart"
            variant="ghost"
            isDisabled={isBuffering}
          />
        </HStack>
        <Progress
          value={progress}
          flex={1}
          size="sm"
          colorScheme="blue"
          onClick={handleSeekClick}
          sx={{ cursor: 'pointer', mx: 4 }}
          borderRadius="md"
        />
        <HStack spacing={4}>
          <Text fontSize="sm">
            {formatTime(currentTime)} / {formatTime(duration)}
          </Text>
        </HStack>
      </Flex>
    </Box>
  );
};

export default AudioPlayer;